import { Body } from '@component-library/helios'
import { type FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import type { BenefitInfo } from 'types/domain'

import { AddMemberContext, getCoveredMembers } from '../../../context/AddMemberContext'

type BenefitListCardElectedProps = {
  benefitInfo: BenefitInfo
}

export const BenefitListCardElected: FC<BenefitListCardElectedProps> = ({
  benefitInfo,
}: BenefitListCardElectedProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const { enrollmentInfo } = useContext(AddMemberContext)

  return (
    <>
      <div>{benefitInfo.electedTier && <Body variant="medium">{t(benefitInfo.electedTier)}</Body>}</div>
      {benefitInfo.electedBuyUp && (
        <div>
          <Body variant="medium">{t('BUY_UP')}</Body>
        </div>
      )}
      <div>
        <Body variant="small" color="caption">
          Covers {getCoveredMembers(enrollmentInfo, benefitInfo)}.
        </Body>
      </div>
      {benefitInfo.electedPlan && (
        <div className="mt-2">
          <Body variant="small" color="caption">
            {t('PLAN')}
          </Body>
          <br />
          <Body variant="medium">
            {benefitInfo.plans.find((plan) => plan.value === benefitInfo.electedPlan)?.label}
          </Body>
        </div>
      )}
    </>
  )
}
