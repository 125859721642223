import { Button, Modal } from '@component-library/helios'
import { useContext, type FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DependentRelationship } from '../../../../types/enums'
import { AddMemberContext, type DependentInfo } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

import * as styles from './Dependents.module.css'
import { DependentsDrawer } from './DependentsDrawer'
import { DependentsTable } from './DependentsTable'

export const Dependents: FC = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [drawerRelationship, setDrawerRelationship] = useState(DependentRelationship.SPOUSE)
  const [editDependentId, setEditDependentId] = useState(0)
  const { t } = useTranslation('translation', { keyPrefix: 'Dependents' })

  const [modalShow, setModalShow] = useState(false)
  const [deleteDependentId, setDeleteDependentId] = useState(0)
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const { setStepHandler } = useContext(AddMemberProgressContext)

  useEffect(() => {
    const onClickNext = async () => true
    setStepHandler({ onClickNext })
  }, [setStepHandler])

  const closeModal = () => setModalShow(false)

  const deleteDependent = (dependentId: number) => {
    const updatedDependents = enrollmentInfo.dependentInfo.filter((dependent) => dependent.id !== dependentId)
    setEnrollmentInfo((enrollmentInfo) => ({
      ...enrollmentInfo,
      dependentInfo: updatedDependents,
    }))
  }

  const spouseDependents: DependentInfo[] = enrollmentInfo.dependentInfo.filter(
    (dependentInfo) => dependentInfo.relationship === DependentRelationship.SPOUSE,
  )
  const childDependents: DependentInfo[] = enrollmentInfo.dependentInfo.filter(
    (dependentInfo) => dependentInfo.relationship === DependentRelationship.DEPENDENT,
  )

  const editDependent = (relationship: DependentRelationship, dependentId: number) => {
    setDrawerRelationship(relationship)
    setEditDependentId(dependentId)
    setShowDrawer(!showDrawer)
  }
  const addDependent = (relationship: DependentRelationship) => {
    setDrawerRelationship(relationship)
    setEditDependentId(0)
    setShowDrawer(!showDrawer)
  }

  return (
    <>
      <p className="mb-3">{t('ADD_DEP_INTRO')}</p>
      <div className={styles.dependentContainer}>
        <h3>{t('RELATIONSHIP.SPOUSE')}</h3>
        {spouseDependents.length > 0 ? (
          <DependentsTable
            variant="spouse"
            dependents={spouseDependents}
            actions={(dependent) => [
              {
                label: 'Edit',
                onClick() {
                  editDependent(dependent.relationship, dependent.id)
                },
              },
              {
                label: 'Delete',
                onClick() {
                  setDeleteDependentId(dependent.id)
                  setModalShow(true)
                },
              },
            ]}
          />
        ) : (
          <Button
            className="dependentButton"
            variant="secondary"
            onClick={() => addDependent(DependentRelationship.SPOUSE)}
          >
            + Add {t('RELATIONSHIP.SPOUSE').toLowerCase()}
          </Button>
        )}
      </div>
      <div className={styles.dependentContainer}>
        <h3>{t('CHILDREN')}</h3>
        {childDependents.length > 0 && (
          <DependentsTable
            variant="child"
            dependents={childDependents}
            actions={(dependent) => [
              {
                label: 'Edit',
                onClick() {
                  editDependent(dependent.relationship, dependent.id)
                },
              },
              {
                label: 'Delete',
                onClick() {
                  setDeleteDependentId(dependent.id)
                  setModalShow(true)
                },
              },
            ]}
          />
        )}
        {childDependents.length < 20 && (
          <Button
            className="dependentButton"
            variant="secondary"
            onClick={() => addDependent(DependentRelationship.DEPENDENT)}
          >
            + Add {t('RELATIONSHIP.DEPENDENT').toLowerCase()}
          </Button>
        )}
      </div>
      <Modal
        id="delete-dependent"
        heading="Are you sure you want to delete this dependent?"
        lang="en"
        show={modalShow}
        onHide={() => closeModal()}
      >
        <Modal.Footer>
          <Button variant="secondary" id="close-button" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            id="action-button"
            onClick={() => {
              deleteDependent(deleteDependentId)
              setModalShow(false)
            }}
          >
            Yes, delete
          </Button>
        </Modal.Footer>
      </Modal>
      <DependentsDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        relationship={drawerRelationship}
        editDependentId={editDependentId}
      />
    </>
  )
}
