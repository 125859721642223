import { DateTime } from 'luxon'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DATE_OF_BIRTH } from '../../../common/enums/regex'
import { FormDateField } from '../../../uiComponents/Form/FormDateField'

import * as styles from './Employment.module.css'

export const HireDateSelect: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })

  const validateHireDate = (dateString: { input: string }): true | string => {
    const today = DateTime.now()
    const hireDate = DateTime.fromFormat(dateString.input, 'MM/dd/yyyy')

    if (dateString.input === '') {
      return t('PLEASE_MAKE_SELECTION')
    }

    if (hireDate.invalidReason) {
      return t('INVALID_DATE')
    }

    if (hireDate > today.plus({ months: 3 })) {
      return t('FUTURE_DATE_ERROR')
    }

    return true
  }

  return (
    <div className={styles.textBoxMargin} data-testid="hireDate">
      <FormDateField
        name="hireDate"
        label={t('HIRE_DATE_TXT')}
        required
        rules={{
          pattern: {
            value: DATE_OF_BIRTH.MM_DD_YYYY.regex,
            message: DATE_OF_BIRTH.MM_DD_YYYY.message,
          },
          validate: validateHireDate,
        }}
      />
    </div>
  )
}
