import { useTranslation } from 'react-i18next'

import { FormDropdownField } from '../../../../uiComponents/Form/FormDropdownField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

const countries = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
]

export const Country = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin} data-testid="country">
      <FormDropdownField
        name="country"
        label={t('COUNTRY')}
        defaultValue={countries[0]}
        options={countries?.map((country) => ({
          label: country.label,
          value: country.value,
        }))}
        required
      />
    </div>
  )
}
