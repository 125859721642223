import { Table, Body, Card } from '@component-library/helios'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import type { MemberDetails } from 'types/domain'

import {
  facilityIDFormatter,
  formatDateShortMonthWithYear,
  formatFullName,
  smokerFormatter,
} from '../../../app/utils/format'
import { MemberSmoker } from '../../../types/enums'
import { ScrollableContainer } from '../ScrollableContainer/ScrollableContainer'

import * as styles from './DepInfoCard.module.css'

export enum Gender {
  Other,
  Male,
  Female,
}

export enum Language {
  English,
  French,
  Spanish,
  Estonian,
  Farsi,
}

type DepInfoCardProps = {
  member: MemberDetails
}

export const DepInfoCard: FC<DepInfoCardProps> = ({ member }: DepInfoCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Dependents' })

  //   console.log(member)
  if (member.dependents.length === 0) {
    return (
      <div className={styles.noDependents}>
        <Card heading="">
          <Body variant="medium">{t('NO_DEPENDENTS')}</Body>
        </Card>
      </div>
    )
  }

  return (
    <>
      <Body variant="medium">{t('DEP_INTRO')}</Body>
      <ScrollableContainer width="1000px">
        <Table
          className={styles.depTable}
          columns={[
            {
              field: 'depName',
              title: t('DEP_NAME'),
              align: 'left',
            },
            {
              field: 'dateOfBirth',
              title: t('DEP_DOB'),
              align: 'left',
            },
            {
              field: 'relationship',
              title: t('DEP_RELATIONSHIP'),
              align: 'left',
            },
            {
              field: 'lastFourSSN',
              title: t('DEP_SSN'),
              align: 'left',
            },
            {
              field: 'gender',
              title: t('DEP_GENDER'),
              align: 'left',
            },
            {
              field: 'dentalId',
              title: t('DEP_DENTAL_ID'),
              align: 'left',
            },
            {
              field: 'smoker',
              title: t('DEP_SMOKER'),
              align: 'left',
            },
            // Actions not in scope for Q2
            // {
            //   field: 'actions',
            //   title: t('ACTIONS'),
            //   align: 'left',
            // },
          ]}
          rows={member.dependents.map((member) => ({
            depName: formatFullName(member),
            dateOfBirth: formatDateShortMonthWithYear(member.dateOfBirth),
            relationship: t('RELATIONSHIP.' + member.relationship),
            lastFourSSN: member.lastFourSSN ? '***-**-' + member.lastFourSSN : '—',
            gender: t('MEMBERGENDER.' + member.gender),
            dentalId: member.facilities.length > 0 ? facilityIDFormatter(member.facilities) : '—',
            smoker: smokerFormatter(member.smoker),
          }))}
        />
      </ScrollableContainer>
    </>
  )
}
