import { useMutation } from '@tanstack/react-query'
import type { Member_EnrollmentRequest } from 'types/domain'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export type UseSubmitMemberEnrollmentReturn = ReturnType<typeof useSubmitMemberEnrollment>

const request = endpoints.Member_Enrollment.getApiRequest()

export const useSubmitMemberEnrollment = () => {
  return useMutation({
    mutationKey: queryKeys.enrollMember(),
    mutationFn: (body: Member_EnrollmentRequest) => request({ body }),
  })
}
