import { Fragment, type ReactNode } from 'react'

import * as styles from './DataDetails.module.css'

type DataDetailsProps = {
  data: Array<{ label: string; value: ReactNode }>
}
export const DataDetails = ({ data }: DataDetailsProps) => (
  <dl className={styles.dataDetails}>
    {data.map((row) => {
      const id = row.label.replace(/\s+/g, '-').toLowerCase()
      return (
        <Fragment key={row.label}>
          <dt id={id}>{row.label}</dt>
          <dd aria-labelledby={id}>{row.value || '—'}</dd>
        </Fragment>
      )
    })}
  </dl>
)
