import { Badge, Button, Heading } from '@component-library/helios'
import { useState, type FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { BenefitInfo } from 'types/domain'

import { AddMemberContext } from '../../../context/AddMemberContext'

import * as styles from './Benefits.module.css'

type BenefitListCardHeaderProps = {
  benefitInfo: BenefitInfo
  isAnyCardOpen?: boolean
  withActions?: boolean
  cardOpen: boolean
  setCardOpen?: (cardOpen: boolean) => void
}

export const BenefitListCardHeader: FC<BenefitListCardHeaderProps> = ({
  benefitInfo,
  isAnyCardOpen = false,
  withActions = true,
  cardOpen,
  setCardOpen = () => {},
}: BenefitListCardHeaderProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)

  const disabled = !cardOpen && isAnyCardOpen ? true : false

  const getBenefitInfo = (benefitId: number) => {
    return enrollmentInfo!.benefitInfo!.filter((benefitInfo) => benefitInfo.id !== benefitId)
  }

  const waiveBenefit = () => {
    setEnrollmentInfo((enrollmentInfo) => ({
      ...enrollmentInfo,
      benefitInfo: [...getBenefitInfo(benefitInfo.id), { ...benefitInfo, waived: true, elected: false }],
    }))
  }

  const editBenefit = () => {
    benefitInfo.elected ? setCardOpen(true) : setCardOpen(false)

    setEnrollmentInfo((enrollmentInfo) => ({
      ...enrollmentInfo,
      benefitInfo: [...getBenefitInfo(benefitInfo.id), { ...benefitInfo, waived: false }],
    }))
  }

  return (
    <div className={styles.benefitCardHeader}>
      <Heading variant="4" className="pr-3">
        {t(benefitInfo.benefitGroup)}
      </Heading>
      {benefitInfo.waived && <Badge variant="info" label="Waived" />}
      {benefitInfo.elected && <Badge variant="success" label="Elected" />}
      {withActions && !cardOpen && (
        <div className={styles.benefitCardActions}>
          {!benefitInfo.waived && (
            <>
              <Button variant="secondary" onClick={() => waiveBenefit()} disabled={disabled}>
                Waive
              </Button>
              {!benefitInfo.elected && (
                <Button
                  variant="primary"
                  onClick={() => {
                    setCardOpen(true)
                  }}
                  disabled={disabled}
                >
                  View options
                </Button>
              )}
            </>
          )}
          {(benefitInfo.waived || benefitInfo.elected) && (
            <Button variant="secondary" onClick={() => editBenefit()} disabled={disabled}>
              Edit
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
