import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EMAIL_REGEX } from '../../../../common/enums/regex'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

export const Email: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin} data-testid="email">
      <FormTextField
        name="email"
        icon="fal fa-envelope"
        iconPosition="before"
        label={t('EMAIL')}
        helperText={t('OPTIONAL')}
        maxLength={80}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: t('INVALID_EMAIL'),
          },
        }}
      />
    </div>
  )
}
