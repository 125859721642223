import { Radio, HelperText, Body } from '@component-library/helios'
import type { ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormRadioGroupProps = {
  name: string
  options: Array<{ label: string; value: string; helperText?: string }>
  label?: string
  required?: boolean
  disabled?: boolean
  inline?: boolean
  rules?: ComponentProps<typeof Controller>['rules']
}

export const FormRadioGroup = ({
  name,
  options,
  rules,
  required = false,
  disabled = false,
  inline = false,
}: FormRadioGroupProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Form' })
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('PLEASE_MAKE_SELECTION'),
        },
        ...rules,
      }}
      render={({ field, fieldState }) => (
        <>
          {options.map((option) => (
            <Radio
              {...field}
              key={option.value}
              id={`${name}-radio-${option.value}`}
              label={
                <>
                  <Body>{option.label}</Body>
                  {option.helperText && <br />}
                  {option.helperText && (
                    <Body variant="small" color="caption">
                      {option.helperText}
                    </Body>
                  )}
                </>
              }
              value={option.value}
              isInvalid={fieldState.invalid}
              checked={field.value === option.value}
              disabled={disabled}
              inline={inline}
              ref={undefined}
            />
          ))}
          {fieldState.invalid && (
            <div>
              <HelperText id={`${name}-error`} error>
                {fieldState.error?.message}
              </HelperText>
            </div>
          )}
        </>
      )}
    />
  )
}
