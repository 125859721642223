import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { USA_ZIP_REGEX, CANADA_POST_REGEX } from '../../../../common/enums/regex'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

type ZipProps = {
  isCanada: boolean
}

export const Zip: FC<ZipProps> = ({ isCanada }: ZipProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin + ' ' + styles.halfRow} data-testid="zip">
      <FormTextField
        name="zip"
        label={isCanada ? t('POSTAL') : t('ZIP')}
        maxLength={isCanada ? 7 : 10}
        rules={{
          pattern: {
            value: isCanada ? CANADA_POST_REGEX : USA_ZIP_REGEX,
            message: isCanada ? t('INVALID_POSTAL') : t('INVALID_ZIP'),
          },
        }}
        required
      />
    </div>
  )
}
