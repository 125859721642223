import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NAME_FIELDS_REGEX } from '../../../../common/enums/regex'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

export const Address: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="address">
        <FormTextField
          name="address"
          label={t('ADDRESS')}
          maxLength={50}
          required
          rules={{
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="address2">
        <FormTextField
          name="address2"
          label={t('ADDRESS_2')}
          maxLength={50}
          helperText={t('OPTIONAL')}
          rules={{
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
        />
      </div>
    </>
  )
}
