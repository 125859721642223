.pageContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15%;
  margin-bottom: 8%;
}

.progress {
  margin-bottom: 2%;
}
