import { createContext, useState, type ReactNode, type Dispatch, type SetStateAction, useEffect } from 'react'

import type { BenefitInfo, BillGroupListItem, DepartmentListItem, MemberGroupUI } from '../../types/domain'
import type { DependentRelationship } from '../../types/enums'
import { useGetEnrollmentInfo } from '../api/hooks/useGetEnrollmentInfo'

enum MemberType {
  EMPLOYEE = 'EMPLOYEE',
  SPOUSE = 'SPOUSE',
  CHILD = 'CHILD',
}

export type DropdownValue = {
  label: string
  value: string
}

export type DatePickerValue = {
  selected: Date
  input: string
}

export interface EmploymentInfo {
  hireDate: DatePickerValue | undefined
  eligibleEmpGrp: DropdownValue | undefined
  signatureDate: DatePickerValue | undefined
  policyId: string
}

export interface DependentInfo {
  id: number
  firstName: string
  middleName: string
  lastName: string
  suffix: string
  dateOfBirth: DatePickerValue | undefined
  genderCode: string
  ssn: string
  relationship: DependentRelationship
}

interface EnrollmentData {
  personalInfo: {
    firstName: string
    middleName: string
    lastName: string
    suffix: string
    dateOfBirth: DatePickerValue | undefined
    genderCode: string
    ssn: string
  }
  mailingAddress: {
    country: DropdownValue | undefined
    address: string
    address2: string
    city: string
    state: DropdownValue | undefined
    zip: string
  }
  contactInfo: {
    email: string
    phoneNum: string
    phoneType: DropdownValue | undefined
  }
  employmentInfo: {
    hireDate: DatePickerValue | undefined
    eligibleEmpGrp: DropdownValue | undefined
    signatureDate: DatePickerValue | undefined
  }
  billingInfo: {
    billLocation: DropdownValue | undefined
    billDepartment: DropdownValue | undefined
  }

  dependentInfo: DependentInfo[]
  benefitInfo: BenefitInfo[] | undefined
  memberGroups: MemberGroupUI[] | undefined
  billGroups: BillGroupListItem[] | undefined
  departments: DepartmentListItem[] | undefined
}

export interface AddMemberContextData {
  enrollmentInfo: EnrollmentData
  setEnrollmentInfo: Dispatch<SetStateAction<EnrollmentData>>
  isCanada: boolean
  setIsCanada: Dispatch<SetStateAction<boolean>>
}

export const AddMemberContext = createContext<AddMemberContextData>({
  enrollmentInfo: {
    personalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      dateOfBirth: undefined,
      genderCode: '',
      ssn: '',
    },
    mailingAddress: {
      country: undefined,
      address: '',
      address2: '',
      city: '',
      state: undefined,
      zip: '',
    },
    contactInfo: {
      email: '',
      phoneNum: '',
      phoneType: undefined,
    },
    employmentInfo: {
      hireDate: undefined,
      eligibleEmpGrp: undefined,
      signatureDate: undefined,
    },
    billingInfo: {
      billLocation: undefined,
      billDepartment: undefined,
    },
    dependentInfo: [],
    benefitInfo: [],
    memberGroups: [],
    billGroups: [],
    departments: [],
  },
  setEnrollmentInfo: () => {},
  isCanada: false,
  setIsCanada: () => {},
})

type AddMemberProviderProps = {
  children: ReactNode
  defaultState?: Partial<EnrollmentData>
}

export const AddMemberProvider = ({ children, defaultState = {} }: AddMemberProviderProps) => {
  const { data: enrollmentOptions } = useGetEnrollmentInfo()
  const [enrollmentInfo, setEnrollmentInfo] = useState<EnrollmentData>({
    personalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      dateOfBirth: undefined,
      genderCode: '',
      ssn: '',
    },
    mailingAddress: {
      country: {
        label: '',
        value: '',
      },
      address: '',
      address2: '',
      city: '',
      state: undefined,
      zip: '',
    },
    contactInfo: {
      email: '',
      phoneNum: '',
      phoneType: {
        label: 'Mobile',
        value: 'mobile',
      },
    },
    employmentInfo: {
      hireDate: undefined,
      eligibleEmpGrp: undefined,
      signatureDate: undefined,
    },
    billingInfo: {
      billLocation: undefined,
      billDepartment: undefined,
    },
    dependentInfo: [],
    benefitInfo: [],
    memberGroups: [],
    billGroups: [],
    departments: [],
    ...defaultState,
  })

  const [isCanada, setIsCanada] = useState<boolean>(false)

  useEffect(() => {
    enrollmentOptions?.enrollmentInfo.benefitInfo.map((benefitInfo, index) => {
      benefitInfo.id = index
    })

    setEnrollmentInfo((prev) => ({
      ...prev,
      benefitInfo: enrollmentOptions?.enrollmentInfo.benefitInfo,
      memberGroups: enrollmentOptions?.enrollmentInfo.memberGroups,
      billGroups: enrollmentOptions?.enrollmentInfo.billGroups,
      departments: enrollmentOptions?.enrollmentInfo.departments,
    }))
  }, [enrollmentOptions, setEnrollmentInfo])

  return (
    <AddMemberContext.Provider
      value={{
        enrollmentInfo,
        setEnrollmentInfo,
        isCanada,
        setIsCanada,
      }}
    >
      {children}
    </AddMemberContext.Provider>
  )
}

export const getMemberGroupBenefits = (enrollmentInfo: EnrollmentData, memberGroup: string = '') => {
  if (!memberGroup) return []

  const filtered = enrollmentInfo?.benefitInfo?.filter(
    (benefit) => benefit.memberGroupId === memberGroup && benefit.policyId === getPolicyId(enrollmentInfo),
  )

  // Sort and set filterd benefits
  return filtered?.sort((a, b) => a.sortOrder - b.sortOrder)
}

export const getPolicyId = (enrollmentInfo: EnrollmentData) =>
  enrollmentInfo.memberGroups!.find(
    (memberGroup) => memberGroup.id === enrollmentInfo.employmentInfo.eligibleEmpGrp!.value,
  )!.policyId!

export const getCoveredMembers = (enrollmentInfo: EnrollmentData, benefitInfo: BenefitInfo): string => {
  let coveredMemberNames: string[] = []
  const coveredMembers: string[] =
    benefitInfo.tiers.find((benefitTier) => benefitTier.tier == benefitInfo.electedTier)?.covers || []
  if (!enrollmentInfo?.dependentInfo) return ''
  coveredMembers.forEach((memberType) => {
    coveredMemberNames = [...coveredMemberNames, getCoveredMemberNames(enrollmentInfo, memberType as MemberType)]
  })

  return coveredMemberNames.join(', ')
}

const getCoveredMemberNames = (enrollmentInfo: EnrollmentData, MemberType: MemberType): string => {
  const memberMap = {
    EMPLOYEE: () => [enrollmentInfo?.personalInfo.firstName],
    SPOUSE: () =>
      enrollmentInfo?.dependentInfo.filter((dep) => dep.relationship === 'SPOUSE').map((dep) => dep.firstName),
    CHILD: () =>
      enrollmentInfo?.dependentInfo.filter((dep) => dep.relationship === 'DEPENDENT').map((dep) => dep.firstName),
  }
  return memberMap[MemberType]?.().filter(Boolean).join(', ')
}
