import { Link, Heading } from '@component-library/helios'
import { useCallback, useContext, useEffect } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { Enrollment_Benefit, Member_EnrollmentRequest } from 'types/domain'

import { DependentRelationship } from '../../../../types/enums'
import { useSubmitMemberEnrollment } from '../../../api/hooks/useSubmitMemberEnrollment'
import {
  AddMemberContext,
  getMemberGroupBenefits,
  getPolicyId,
  type AddMemberContextData,
} from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'
import { DataDetails } from '../../../uiComponents/DataDetails/DataDetails'
import { Stack } from '../../../uiComponents/Stack/Stack'
import { formatDateFullMonthWithYear, formatFullName, formatSSN, getAge } from '../../../utils/format'
import { BenefitListCard } from '../Benefits/BenefitListCard'
import { DependentsTable } from '../Dependents/DependentsTable'
import { type AddMemberStepId } from '../hooks'

import { TermsConditions } from './TermsConditions'

type ReviewProps = {
  onStepChange: (step: AddMemberStepId) => void
}

const formatAddress = (mailingAddress: AddMemberContextData['enrollmentInfo']['mailingAddress']) =>
  [
    mailingAddress.address,
    mailingAddress.address2,
    mailingAddress.city,
    mailingAddress.state?.value,
    mailingAddress.zip,
    mailingAddress.country?.label,
  ]
    .filter(Boolean)
    .join(', ')

export const Review = ({ onStepChange }: ReviewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Review' })
  const methods: UseFormReturn = useForm({
    mode: 'onChange',
  })
  const navigate = useNavigate()
  const { setStepHandler, setIsError, setErrorMsg } = useContext(AddMemberProgressContext)
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { isPending, isSuccess, data, mutateAsync } = useSubmitMemberEnrollment()

  const getEnrollmentRequest = useCallback(() => {
    const groupId = enrollmentInfo.employmentInfo.eligibleEmpGrp!.value
    const policyId = getPolicyId(enrollmentInfo)

    return {
      memberInformation: {
        addressLine1: enrollmentInfo.mailingAddress.address,
        addressLine2: enrollmentInfo.mailingAddress.address2,
        addressLine3: '',
        city: enrollmentInfo.mailingAddress.city,
        countryCode: enrollmentInfo.mailingAddress.country!.value,
        dateOfBirth: enrollmentInfo.personalInfo.dateOfBirth!.selected.toISOString(),
        email: enrollmentInfo.contactInfo.email,
        firstName: enrollmentInfo.personalInfo.firstName,
        gender: enrollmentInfo.personalInfo.genderCode,
        lastName: enrollmentInfo.personalInfo.lastName,
        middleName: enrollmentInfo.personalInfo.middleName,
        nationalId: enrollmentInfo.personalInfo.ssn,
        phoneNumber: enrollmentInfo.contactInfo.phoneNum,
        phoneType: enrollmentInfo.contactInfo.phoneType!.value,
        state: enrollmentInfo.mailingAddress.state!.value,
        suffix: enrollmentInfo.personalInfo.suffix,
        zipCode: enrollmentInfo.mailingAddress.zip,
      },
      employment: {
        groupId: groupId,
        dateHire: enrollmentInfo.employmentInfo.hireDate!.selected.toISOString(),
        policyId: policyId,
        signatureDate: enrollmentInfo.employmentInfo.signatureDate!.selected.toISOString(),
      },
      dependents: enrollmentInfo.dependentInfo.map((dependent) => ({
        dateOfBirth: dependent.dateOfBirth!.selected.toISOString(),
        firstName: dependent.firstName,
        gender: dependent.genderCode,
        lastName: dependent.lastName,
        middleName: dependent.middleName,
        suffix: dependent.suffix,
        nationalId: dependent.ssn,
        relationship: dependent.relationship,
      })),
      benefits: enrollmentInfo
        .benefitInfo!.filter((benefitInfo) => !benefitInfo.waived && benefitInfo.elected)
        .flatMap((benefitInfo): Enrollment_Benefit[] => {
          const electedTier = benefitInfo.tiers.find((tier) => tier.tier === benefitInfo.electedTier)!
          const electedPlan = benefitInfo.planInfo.find((plan) => plan.value === benefitInfo.electedPlan)!

          const buyUpBenefitIds = benefitInfo.electedBuyUp ? electedTier.buyUpBenefitIds : []
          const includedBenefitIds = electedTier.includedBenefitIds
          // merge buyUp and included benefits
          const benefitIds = [...buyUpBenefitIds, ...includedBenefitIds]

          return benefitIds.map((benefitId) => ({
            benefitId: benefitId,
            planId:
              benefitInfo.planInfo.find((plan) => plan.benefitId === benefitId && plan.value === electedPlan.value)
                ?.coverageLevelId || '',
            waived: false,
            groupId: groupId,
            policyId: policyId,
          }))
        }),
      billing: {
        billGroupId: enrollmentInfo.billingInfo.billLocation!.value,
        departmentId: enrollmentInfo.billingInfo.billDepartment?.value,
      },
    } as Member_EnrollmentRequest
  }, [enrollmentInfo])

  const onClickNext = useCallback(async () => {
    const enrollmentRequest = getEnrollmentRequest()
    try {
      // Submit Enrollment
      if (!isPending) {
        await mutateAsync(enrollmentRequest)
      }
    } catch (error) {
      setIsError(true)
      // TODO: Show server validation errors
      // eslint-disable-next-line no-console
      console.log({ error })
      setErrorMsg('An error occurred submitting the request, please contact support')
    }

    return false
  }, [getEnrollmentRequest, isPending, mutateAsync, setIsError, setErrorMsg])

  useEffect(() => {
    setStepHandler({ onClickNext })
  }, [setStepHandler, onClickNext])

  if (isSuccess) {
    navigate(`/enrollment-status/${data?.transactionId}`)
  }

  const spouse = enrollmentInfo.dependentInfo.filter(
    (dependent) => dependent.relationship === DependentRelationship.SPOUSE,
  )
  const children = enrollmentInfo.dependentInfo.filter(
    (dependent) => dependent.relationship === DependentRelationship.DEPENDENT,
  )

  return (
    <FormProvider {...methods}>
      <div className="col-lg-12 col-sm-12 col-xs-12">
        <p className="mb-4">{t('REVIEW_TXT')}</p>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('MEMBER_INFORMATION')}
            </Heading>
            <Link onClick={() => onStepChange('member_info')}>Edit</Link>
          </Stack>
          <DataDetails
            data={[
              { label: 'Name', value: formatFullName(enrollmentInfo.personalInfo) },
              {
                label: 'Date of birth',
                value: enrollmentInfo.personalInfo.dateOfBirth?.selected
                  ? formatDateFullMonthWithYear(enrollmentInfo.personalInfo.dateOfBirth.selected) +
                    ` (${t('YEARS_OLD', { count: getAge(enrollmentInfo.personalInfo.dateOfBirth.selected) })})`
                  : null,
              },
              { label: 'Gender', value: t('MEMBERGENDER.' + enrollmentInfo.personalInfo.genderCode) },
              { label: 'SSN', value: formatSSN(enrollmentInfo.personalInfo.ssn) },
              { label: 'Address', value: formatAddress(enrollmentInfo.mailingAddress) },
              { label: 'Email', value: enrollmentInfo.contactInfo.email },
              {
                label: 'Phone',
                value: enrollmentInfo.contactInfo.phoneNum
                  ? `${enrollmentInfo.contactInfo.phoneNum} (${enrollmentInfo.contactInfo.phoneType?.label})`
                  : null,
              },
            ]}
          />
        </div>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('EMPLOYMENT')}
            </Heading>
            <Link onClick={() => onStepChange('employment')}>Edit</Link>
          </Stack>
          <DataDetails
            data={[
              {
                label: 'Hire date',
                value: enrollmentInfo.employmentInfo.hireDate?.selected
                  ? formatDateFullMonthWithYear(enrollmentInfo.employmentInfo.hireDate.selected)
                  : null,
              },
              { label: 'Eligible employee group', value: enrollmentInfo.employmentInfo.eligibleEmpGrp?.label },
              {
                label: 'Signature date',
                value: enrollmentInfo.employmentInfo.signatureDate?.selected
                  ? formatDateFullMonthWithYear(enrollmentInfo.employmentInfo.signatureDate?.selected)
                  : null,
              },
            ]}
          />
        </div>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('DEPENDENTS')}
            </Heading>
            <Link onClick={() => onStepChange('dependents')}>Edit</Link>
          </Stack>
          {spouse.length > 0 && (
            <div className="mb-3">
              <p>Spouse / domestic partner</p>
              <DependentsTable
                variant="spouse"
                dependents={enrollmentInfo.dependentInfo.filter(
                  (dependent) => dependent.relationship === DependentRelationship.SPOUSE,
                )}
              />
            </div>
          )}
          {children.length > 0 && (
            <div>
              <p>Children</p>
              <DependentsTable
                variant="child"
                dependents={enrollmentInfo.dependentInfo.filter(
                  (dependent) => dependent.relationship === DependentRelationship.DEPENDENT,
                )}
              />
            </div>
          )}
        </div>
        {enrollmentInfo.benefitInfo && (
          <div className="mb-5">
            <Stack direction="row" growFirst>
              <Heading variant="3" className="mb-3">
                {t('BENEFITS')}
              </Heading>
              <Link onClick={() => onStepChange('benefits')}>Edit</Link>
            </Stack>
            {getMemberGroupBenefits(enrollmentInfo, enrollmentInfo?.employmentInfo?.eligibleEmpGrp?.value)?.map(
              (benefitInfo) => (
                <BenefitListCard key={benefitInfo.benefitGroup} benefitInfo={benefitInfo} withActions={false} />
              ),
            )}
          </div>
        )}
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('BILLING')}
            </Heading>
            <Link onClick={() => onStepChange('billing')}>Edit</Link>
          </Stack>
          <DataDetails
            data={[
              { label: 'Billing location', value: enrollmentInfo.billingInfo.billLocation?.label },
              { label: 'Billing department', value: enrollmentInfo.billingInfo.billDepartment?.label },
            ]}
          />
        </div>
        <Heading variant="3">{t('TERMS_CONDITIONS')}</Heading>
        <TermsConditions />
      </div>
    </FormProvider>
  )
}
