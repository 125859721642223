import { Dropdown } from '@component-library/helios'
import { type ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormDropdownFieldProps = {
  name: string
  label: string
  defaultValue?: { label: string; value: string }
  options: Array<{ label: string; value: string }>
  required?: boolean
  helperText?: string
  rules?: ComponentProps<typeof Controller>['rules']
}

export const FormDropdownField = ({
  name,
  label,
  options,
  defaultValue,
  helperText,
  required = false,
  rules,
}: FormDropdownFieldProps) => {
  const { control, setValue, getValues, trigger } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Form' })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: required,
          message: t('PLEASE_MAKE_SELECTION'),
        },
        validate: (): true | string => {
          if (required && getValues(name).value === '') {
            return t('REQUIRED')
          }
          return true
        },
        ...rules,
      }}
      render={({ field, fieldState }) => (
        <Dropdown
          {...field}
          id={field.name}
          label={label}
          options={options}
          selected={field.value?.value}
          inputProps={undefined}
          inputRef={undefined}
          ref={undefined}
          placeholder={undefined}
          error={fieldState?.invalid}
          helperText={fieldState.error ? fieldState.error.message : helperText}
          onChange={({ option }) => {
            setValue(name, option)
            trigger(name)
          }}
        />
      )}
    />
  )
}
