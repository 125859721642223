import { DatePicker, HelperText } from '@component-library/helios'
import { type ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormDateFieldProps = {
  name: string
  label: string
  required?: boolean
  disabled?: boolean
  helperText?: string
  rules?: ComponentProps<typeof Controller>['rules']
}

export const FormDateField = ({
  name,
  label,
  required = false,
  disabled = false,
  helperText,
  rules,
}: FormDateFieldProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Form' })

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('PLEASE_MAKE_SELECTION'),
        },
        ...rules,
      }}
      render={({ field, fieldState }) => (
        <>
          <DatePicker
            {...field}
            id={field.name}
            label={label}
            error={fieldState?.invalid}
            defaultValue={undefined}
            minDate=""
            maxDate=""
            isModal={false}
            inputProps={undefined}
            inputRef={undefined}
            ref={undefined}
            localeTexts={undefined}
            /* Helios DatePicker invalid helperText type */
            helperText={helperText as undefined}
            disabled={disabled}
          />
          {fieldState.invalid && (
            <div>
              <HelperText id={`${name}-error`} error>
                {fieldState.error?.message}
              </HelperText>
            </div>
          )}
        </>
      )}
    />
  )
}
