.cardMargin {
  margin-top: 1rem;
  padding-bottom: 0.75rem;
}

.noBenefits {
  display: block;
  text-align: center;
  h4 {
    display: none;
  }
}

.policyNumber {
  color: #5b6062;
  font-size: 14px;
  line-height: 0.51rem;
  margin-left: 0.1rem;
  padding-bottom: 0.5rem;
}
