import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SSN_REGEX } from '../../../../common/enums/regex'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'

import * as styles from './PersonalInfo.module.css'

interface SSNProps {
  optionalText?: string
  isDuplicateSSN?: (ssn: string) => boolean
}

export const Ssn: FC<SSNProps> = ({ optionalText = 'Optional', isDuplicateSSN }: SSNProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin} data-testid="ssn">
      <FormTextField
        name="ssn"
        label={t('SSN')}
        helperText={optionalText}
        maxLength={9}
        minLength={9}
        rules={{
          pattern: {
            value: SSN_REGEX,
            message: t('INVALID_SSN'),
          },

          validate: (value) => (value && isDuplicateSSN ? !isDuplicateSSN(value) : true) || t('DUPLICATE_SSN'),
        }}
      />
    </div>
  )
}
