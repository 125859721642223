import { Breadcrumbs, Link, Heading, Icon, Button, Notification } from '@component-library/helios'
import type { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link as ReactRouterDomLink, useLocation, useNavigate } from 'react-router-dom'

import { useGetFeatureToggles } from '../../../api/hooks/useGetFeatureToggles'
import { useGetMemberById } from '../../../api/hooks/useGetMemberById'
import { DepInfoCard } from '../../../components/DepInfoCard/DepInfoCard'
import { MemberBenefits } from '../../../components/MemberBenefits/MemberBenefits'
import { MemberInfoCard } from '../../../components/MemberInfoCard/MemberInfoCard'
import { PageContent } from '../../../components/PageContent/PageContent'
import { PolicyInfoCard } from '../../../components/PolicyInfoCard/PolicyInfoCard'
import { QueryResult } from '../../../components/QueryResult/QueryResult'
import { useAutoScrollTop } from '../../../hooks/useAutoScrollTop'
import { hasDentalCoverage } from '../../../utils/benefits'
import { formatDateFullMonthWithYear } from '../../../utils/format'

import * as styles from './MemberDetail.module.css'

// eslint-disable-next-line import/no-unused-modules
export const MemberDetail: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'MemberDetail' })
  useAutoScrollTop()
  const { memberId } = useParams()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const navigate = useNavigate()
  const getMemberById = useGetMemberById({ params: { memberId: memberId } }, { enabled: typeof memberId === 'string' })
  const { data: featureToggles } = useGetFeatureToggles()
  // TODO: use closeActionNotification after Helios component upgrade
  const closeActionNotification = () => {
    searchParams.delete('action')
    navigate({ search: searchParams.toString() }, { replace: true })
  }

  return (
    <>
      <QueryResult query={getMemberById}>
        {(data) => {
          const member = data?.member

          const memberName =
            member?.firstName + ' ' + member?.middleName + ' ' + member?.lastName + ' ' + member?.suffix
          const items: ComponentProps<typeof Breadcrumbs>['items'] = [
            {
              href: '/members',
              id: 'members',
              label: t('MEMBER_BREADCRUMB'),
            },
            {
              id: `${memberName ?? 'memberName'}`,
              label: `${memberName ?? ''}`,
            },
          ]

          const showDentalButton = featureToggles?.MEMBER_MGMT_DENTAL_ID_CARD && hasDentalCoverage(member?.benefits)
          const handleDentalIdClick = () => {
            window.open(`/member/${memberId}/dental-id`, '_blank', 'noopener,noreferrer')
          }

          return (
            <>
              <main>
                {searchParams.get('action') === 'enrollComplete' && (
                  <Notification
                    variant="success"
                    heading={t('MEMBER_ENROLLED_SUCCESS')}
                    // TODO: enable after Helios component upgrade
                    // onClose={closeActionNotification}
                  />
                )}
                <PageContent>
                  <div className="d-none d-lg-block">
                    <Breadcrumbs
                      items={items}
                      className={styles.breadcrumbs}
                      itemRender={(props, index) => {
                        const isLast = index === items?.length - 1
                        return isLast ? undefined : (
                          <Link as={ReactRouterDomLink} to={props.href ?? '#'} size="sm">
                            {props.label}
                          </Link>
                        )
                      }}
                    />
                  </div>
                  <div className={`d-lg-none d-xl-none ${styles.marginTop1rem}`}>
                    <Link as={ReactRouterDomLink} to={'/members'} size="sm">
                      <Icon name="chevron-left" size="sm" className={styles.mobileIcon}></Icon>
                      {t('MOBILE_BACK_BTN')}
                    </Link>
                  </div>
                  <div className={styles.entityHeader}>
                    <div className={styles.headerContainer}>
                      <Heading variant="1" as="h1">
                        {memberName}
                      </Heading>
                      {showDentalButton && (
                        <Button variant="secondary" className={styles.dentalButton} onClick={handleDentalIdClick}>
                          View Dental ID Card
                        </Button>
                      )}
                    </div>
                    <div className={styles.memberHeaderAttr}>
                      <div className={styles.cardLabel}>
                        {t('MEM_NUM_LABEL')}
                        {member?.externalId}
                      </div>
                      {member?.updatedAt && (
                        <div className={styles.cardLabel}>
                          {t('MEM_MODIFIED_LABEL')}
                          {formatDateFullMonthWithYear(member.updatedAt)}
                        </div>
                      )}
                    </div>
                    <hr />
                  </div>

                  <div className={styles.cardTitle}>{t('DETAIL_TITLE')}</div>
                  <div className={styles.cardMargin}>
                    <MemberInfoCard member={member} />
                  </div>
                  <div className={styles.cardTitle}>{t('BENEFITS_TITLE')}</div>
                  <MemberBenefits benefits={member.benefits} policies={member.policyData} />
                  <div>
                    <div className={styles.cardTitle}>{t('DEP_TITLE')}</div>
                    <div className={styles.cardMargin}>
                      <DepInfoCard member={member} />
                    </div>
                  </div>
                  <div className={styles.cardTitle}>{t('POLICY_TITLE')}</div>
                  <div className={styles.cardMargin}>
                    <PolicyInfoCard member={member} />
                  </div>
                </PageContent>
              </main>
            </>
          )
        }}
      </QueryResult>
    </>
  )
}
