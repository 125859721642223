import { TextField as HeliosTextField } from '@component-library/helios'
import type { ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormTextFieldProps = {
  name: string
  label: string
  helperText?: string
  icon?: string
  iconPosition?: ComponentProps<typeof HeliosTextField>['iconPosition']
  minLength?: number
  maxLength?: number
  required?: boolean
  rules?: ComponentProps<typeof Controller>['rules']
}

export const FormTextField = ({
  name,
  label,
  helperText,
  icon,
  iconPosition,
  minLength,
  maxLength,
  rules,
  required = false,
}: FormTextFieldProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Form' })

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('REQUIRED'),
        },
        ...rules,
      }}
      render={({ field, fieldState }) => (
        <HeliosTextField
          {...field}
          id={field.name}
          label={label}
          error={fieldState.invalid}
          helperText={fieldState.error ? fieldState.error.message : helperText}
          minLength={minLength}
          maxLength={maxLength}
          icon={icon}
          iconPosition={iconPosition}
          ref={undefined}
        />
      )}
    />
  )
}
