import { DateTime } from 'luxon'
import { type FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DATE_OF_BIRTH } from '../../../common/enums/regex'
import { FormDateField } from '../../../uiComponents/Form/FormDateField'

import * as styles from './Employment.module.css'

export const SignatureDate: FC = () => {
  const { getValues } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })

  const isDateValid = (dateString: { input: string }): boolean => {
    const sigDate = DateTime.fromFormat(dateString.input, 'MM/dd/yyyy')
    const hireDateValue = getValues('hireDate')

    if (!hireDateValue) {
      return false
    }

    if (sigDate.invalidReason) {
      return false
    }

    const formatHireDate = DateTime.fromFormat(hireDateValue.input, 'MM/dd/yyyy')
    if (sigDate < formatHireDate) {
      return false
    }

    return true
  }

  const validateSigDate = (dateString: { input: string }): true | string => {
    if (dateString.input === '') {
      return t('PLEASE_MAKE_SELECTION')
    }

    //This is to prevent the UI from displaying an invalid date error when the hire date is not yet entered
    //When a hire date is entered, the UI will display the invalid date error if the signature date is invalid
    const hireDateValue = getValues('hireDate')
    if (!hireDateValue) {
      return true
    }

    if (isDateValid(dateString)) {
      return true
    }

    return t('INVALID_DATE')
  }

  return (
    <div className={styles.justBottomMargin} data-testid="sigDate">
      <FormDateField
        name="signatureDate"
        label={t('SIG_DATE_LABEL')}
        required
        rules={{
          pattern: {
            value: DATE_OF_BIRTH.MM_DD_YYYY.regex,
            message: DATE_OF_BIRTH.MM_DD_YYYY.message,
          },
          validate: validateSigDate,
        }}
      />
    </div>
  )
}
