import { Progress, Link } from '@component-library/helios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterDomLink, useParams } from 'react-router-dom'

import { TransactionStatus } from '../../../../types/enums'
import { useGetTransactionById } from '../../../api/hooks/useGetTransactionById'
import { PageContent } from '../../../components/PageContent/PageContent'

import * as styles from './CheckEnrollmentStatus.module.css'

export const CheckEnrollmentStatus = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'EnrollmentStatus' })
  const { transactionId } = useParams()
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(TransactionStatus.IN_PROGRESS)
  const { data, error, refetch } = useGetTransactionById(
    { params: { transactionId: transactionId } },
    { enabled: true },
  )
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  const clearIntervalAndTimeout = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }
  }, [])

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      // Poll for enrollment status
      refetch()
    }, 2000)

    timeoutIdRef.current = setTimeout(() => {
      clearIntervalAndTimeout()
      setTransactionStatus(TransactionStatus.FAILED)
    }, 180000) // End polling after 3 minutes

    return () => {
      clearIntervalAndTimeout()
    }
  }, [refetch, clearIntervalAndTimeout])

  useEffect(() => {
    if (error || data?.transactionStatus === TransactionStatus.FAILED) {
      clearIntervalAndTimeout()
      setTransactionStatus(TransactionStatus.FAILED)
    }

    if (data?.transactionStatus === TransactionStatus.COMPLETED && data?.memberId) {
      clearIntervalAndTimeout()
      setTransactionStatus(TransactionStatus.COMPLETED)
    }
  }, [data, error, clearIntervalAndTimeout])

  return (
    <>
      <main className={styles.pageContent}>
        <PageContent>
          {transactionStatus === TransactionStatus.IN_PROGRESS && (
            <>
              <Progress id="submitProgress" label="" variant="indeterminate" className={styles.progress} />
              <p>{t('ENROLLMENT_PROCESSING')}</p>
              <p>{t('ESTIMATED_COMPLETION')}</p>
              <Link as={ReactRouterDomLink} showIcon to={`/members`}>
                {t('RETURN_TO_MEMBERS')}
              </Link>
            </>
          )}
          {transactionStatus === TransactionStatus.COMPLETED && (
            <>
              <p>{t('THANKS_FOR_WAITING')}</p>
              <p>{t('ENROLLMENT_COMPLETE')}</p>
              <Link as={ReactRouterDomLink} showIcon to={`/member/${data?.memberId}?action=enrollComplete`}>
                {t('SEE_MEMBER')}
              </Link>
            </>
          )}
          {transactionStatus !== TransactionStatus.IN_PROGRESS && transactionStatus !== TransactionStatus.COMPLETED && (
            <>
              <p>{t('THANKS_FOR_WAITING')}</p>
              <p>{t('ENROLLMENT_PENDING')}</p>
              <Link as={ReactRouterDomLink} showIcon to={`/members`}>
                {t('RETURN_TO_MEMBERS')}
              </Link>
            </>
          )}
        </PageContent>
      </main>
    </>
  )
}
