import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormRadioGroup } from '../../../../uiComponents/Form/FormRadioGroup'

import * as styles from './PersonalInfo.module.css'

interface GenderProps {
  label?: string
  isViewOnly?: boolean
  helperText?: string
  isRequired?: boolean
  inline?: boolean
}

export const Gender: FC<GenderProps> = ({ isViewOnly = false, isRequired = true, inline = false }: GenderProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin} data-cy="radio_gender_group">
      <h5>{t('GENDER')}</h5>
      <FormRadioGroup
        name="genderCode"
        options={[
          { value: 'FEMALE', label: t('FEMALE') },
          { value: 'MALE', label: t('MALE') },
          { value: 'UNIDENTIFIED', label: t('PREF_NOT_SAY') },
        ]}
        required={isRequired}
        disabled={isViewOnly}
        inline={inline}
      />
    </div>
  )
}
