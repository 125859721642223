import { useContext, useEffect, type FC } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'

import { AddMemberContext, type DropdownValue } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

import { BillingLocation } from './BillingLocation'
import { Department } from './Department'

interface DefaultBillingValues {
  billLocation: DropdownValue | undefined
  billDepartment: DropdownValue | undefined
}

const defaultBillingValues: DefaultBillingValues = {
  billLocation: undefined,
  billDepartment: undefined,
}

export const Billing: FC = () => {
  const methods: UseFormReturn<DefaultBillingValues> = useForm<DefaultBillingValues>({
    mode: 'onChange',
    defaultValues: defaultBillingValues,
  })
  const { trigger, getValues, reset } = methods
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const { setStepHandler, setIsError } = useContext(AddMemberProgressContext)

  useEffect(() => {
    const onClickNext = async () => {
      const isValid = await trigger()
      if (!isValid) {
        setIsError(true)
      }
      if (isValid) {
        setIsError(false)
        const { billLocation, billDepartment } = getValues()

        setEnrollmentInfo((enrollmentInfo) => ({
          ...enrollmentInfo,
          billingInfo: { billLocation, billDepartment },
        }))
      }
      return isValid
    }
    setStepHandler({ onClickNext })
  }, [getValues, trigger, setEnrollmentInfo, setStepHandler, setIsError])

  useEffect(() => {
    if (enrollmentInfo) {
      reset({ ...enrollmentInfo.billingInfo, ...enrollmentInfo.billGroups, ...enrollmentInfo.departments })
    }
  }, [enrollmentInfo, reset])

  return (
    <FormProvider {...methods}>
      <div className="col-lg-6 col-sm-6 col-xs-12">
        <BillingLocation />
      </div>
      {(enrollmentInfo.departments?.length ?? 0) > 0 && (
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <Department />
        </div>
      )}
    </FormProvider>
  )
}
