import { Body } from '@component-library/helios'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { MemberDetails } from 'types/domain'

import { BenefitCategory } from '../../../types/enums'
import { DataDetails } from '../../uiComponents/DataDetails/DataDetails'

import * as styles from './PolicyInfoCard.module.css'

type PolicyInfoCardProps = {
  member: MemberDetails
}

const TranslateBenefitCategory = (categories: string): string => {
  const { t } = useTranslation('translation', { keyPrefix: 'BenefitCategories' })

  const categoryOrder = [
    BenefitCategory.LIFE,
    BenefitCategory.DISABILITY,
    BenefitCategory.EAP,
    BenefitCategory.LEAVE,
    BenefitCategory.DENTAL,
    BenefitCategory.HOSPITAL_INDEMNITY,
    BenefitCategory.ACCIDENT,
    BenefitCategory.CRITICAL_ILLNESS,
    BenefitCategory.CANCER,
    BenefitCategory.VISION,
    'UNKNOWN',
  ]

  const categoryArray = categories.split(',').map((category) => category.trim())

  // Sort the categories based on the defined order
  const sortedCategories = categoryArray.sort((a, b) => {
    const indexA = categoryOrder.indexOf(a)
    const indexB = categoryOrder.indexOf(b)

    // If a category is not in the order array, place it at the end
    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return indexA - indexB
  })

  // Translate and join the sorted categories
  const translatedCategories = sortedCategories.map((category) => t(category)).join(', ')

  return translatedCategories
}

export const PolicyInfoCard: FC<PolicyInfoCardProps> = ({ member }: PolicyInfoCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Policies' })

  return (
    <div>
      {member.policyData?.map((policy, index) => (
        <Body key={index} variant="medium" className={styles.policyinfo}>
          <div className={styles.greybox}>
            <div className="col-sm-7">
              <Body variant="medium-bold">
                {t('MEMBER_POLICY_NUMBER')}
                {policy.qpsPolicyID}
              </Body>

              <div className={styles.spacer}></div>
              <DataDetails
                data={[
                  { label: t('MEMBER_ELIGIBLE_EMP_GRP'), value: policy.memberGroup },
                  { label: t('MEMBER_BILL_LOCATION'), value: policy.memberBillGroup },
                  ...(policy.memberDepartment
                    ? [
                        {
                          label: t('MEMBER_DEPT'),
                          value: policy.memberDepartment,
                        },
                      ]
                    : []),
                  ...(policy.benefitCategory
                    ? [
                        {
                          label: t('MEMBER_BENEFITS'),
                          value: TranslateBenefitCategory(policy.benefitCategory),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          </div>
          <div className={styles.spacer}></div>
        </Body>
      ))}
    </div>
  )
}
