import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NAME_FIELDS_REGEX } from '../../../../common/enums/regex'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

export const City: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin} data-testid="city">
      <FormTextField
        name="city"
        label={t('CITY')}
        maxLength={20}
        required
        rules={{
          pattern: {
            value: NAME_FIELDS_REGEX,
            message: t('INVALID'),
          },
        }}
      />
    </div>
  )
}
