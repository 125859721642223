import { useContext, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AddMemberContext, getPolicyId } from '../../../context/AddMemberContext'
import { FormDropdownField } from '../../../uiComponents/Form/FormDropdownField'

import * as styles from './Billing.module.css'

export const BillingLocation: FC = () => {
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { t } = useTranslation('translation', { keyPrefix: 'Billing' })

  const billGroups =
    enrollmentInfo.billGroups?.filter((billgroup) => billgroup.policyId === getPolicyId(enrollmentInfo)) || []
  const isSingleLocation = billGroups.length === 1

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="billLocation">
        <FormDropdownField
          name="billLocation"
          label={t('BILL_LOCATION')}
          defaultValue={isSingleLocation ? { label: billGroups[0].name, value: billGroups[0].id } : undefined}
          helperText={t('BILL_LOC_TXT')}
          options={
            billGroups?.map((billLocation) => ({
              label: billLocation.name,
              value: billLocation.id,
            })) ?? []
          }
          required
        />
      </div>
    </>
  )
}
