import { Body, Checkbox, HelperText } from '@component-library/helios'
import type { ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormCheckboxProps = {
  name: string
  label: string
  helperText?: string
  required?: boolean
  disabled?: boolean
  inline?: boolean
  rules?: ComponentProps<typeof Controller>['rules']
}

export const FormCheckbox = ({
  name,
  label,
  helperText,
  rules,
  required = false,
  disabled = false,
  inline = false,
}: FormCheckboxProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Form' })
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('REQUIRED'),
        },
        ...rules,
      }}
      render={({ field, fieldState }) => (
        <>
          <Checkbox
            {...field}
            id={field.name}
            label={
              <>
                <Body>{label}</Body>
                {helperText && <br />}
                {helperText && (
                  <Body variant="small" color="caption">
                    {helperText}
                  </Body>
                )}
              </>
            }
            value="true"
            isInvalid={fieldState.invalid}
            checked={field.value}
            disabled={disabled}
            inline={inline}
            ref={undefined}
          />
          {fieldState.invalid && (
            <div>
              <HelperText id={`${name}-error`} error>
                {fieldState.error?.message}
              </HelperText>
            </div>
          )}
        </>
      )}
    />
  )
}
