.breadcrumbs {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  line-height: 1;
}

.breadcrumbs > :global(.breadcrumb-item),
.breadcrumbs > :global(.current-item) {
  margin: 0 !important;
}

.mobileIcon {
  margin-bottom: -0.3rem;
}

.marginTop1rem {
  margin-top: 1rem;
}

.marginTop1rem span {
  background: var(--brand-tertiary-800);
}

.margin2rem {
  margin-top: 2rem;
}

.marginTopHalfrem {
  margin-top: 0.5rem;
}

.removeMargins {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Sun Life New Text Regular;
}

.cardLabel {
  font-family: Sun Life New Text Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #3d4142;
  border-right: solid 2px var(--grey-300);
  padding-left: 1rem;
  padding-right: 1rem;
}
.memberHeaderAttr :first-child {
  padding-left: 0rem;
}
.memberHeaderAttr :last-child {
  padding-right: 0rem;
  border-right: none;
}

.cardInnerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entityHeader {
  padding-top: 24px;
  border: none;
}

.memberHeaderAttr {
  display: flex;
}

.cardTitle {
  color: var(--grey-900);
  font-family:
    Sun Life New Text Regular,
    Helvetica Neue,
    Calibri,
    sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
  margin-top: 2.5rem;
}

.cardMargin {
  margin-top: 1rem;
  padding-bottom: 0.75rem;
}

@media (max-width: 575.98px) {
  .memberHeaderAttr {
    flex-direction: column;
  }
  .memberHeaderAttr > .cardLabel {
    padding: 0 0;
    border: none;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family:
    Sun Life New Text Regular,
    Helvetica Neue,
    Calibri,
    sans-serif;
}

.dentalButton {
  margin-left: 1rem;
  white-space: nowrap;
  font-family: Sun Life New Text Regular;
}

@media (max-width: 575.98px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .dentalButton {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
}
