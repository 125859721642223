import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PHONE_REGEX } from '../../../../common/enums/regex'
import { FormDropdownField } from '../../../../uiComponents/Form/FormDropdownField'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

const phoneTypes = [
  {
    label: 'Mobile',
    value: 'mobile',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
]

export const Phone: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.phoneCtr}>
      <div className={styles.textBoxMargin + ' ' + styles.threeQuarRow} data-testid="phoneNum">
        <FormTextField
          name="phoneNum"
          icon="fal fa-phone-alt"
          iconPosition="before"
          label={t('PHONE_NUM')}
          helperText={t('OPTIONAL')}
          maxLength={30}
          rules={{
            pattern: {
              value: PHONE_REGEX,
              message: t('INVALID_PHONE_NUM'),
            },
          }}
        />
      </div>
      <div className={styles.textBoxMargin + ' ' + styles.oneQuarRow} data-testid="phoneType">
        <FormDropdownField
          name="phoneType"
          label={t('PHONE_TYPE')}
          defaultValue={phoneTypes[0]}
          options={phoneTypes.map((phoneType) => ({
            label: phoneType.label,
            value: phoneType.value,
          }))}
        />
      </div>
    </div>
  )
}
