import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { states, provinces } from '../../../../common/mockStates'
import { FormDropdownField } from '../../../../uiComponents/Form/FormDropdownField'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

type StateProps = {
  isCanada: boolean
}

export const State: FC<StateProps> = ({ isCanada }: StateProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <div className={styles.textBoxMargin + ' ' + styles.halfRow} data-testid="state">
      <FormDropdownField
        name="state"
        label={isCanada ? t('TERRITORY') : t('STATE')}
        options={
          isCanada
            ? provinces?.map((province) => ({
                label: province.label,
                value: province.value,
              }))
            : states?.map((state) => ({
                label: state.label,
                value: state.value,
              }))
        }
        required
      />
    </div>
  )
}
