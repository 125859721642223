import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NAME_FIELDS_REGEX } from '../../../../common/enums/regex'
import { FormTextField } from '../../../../uiComponents/Form/FormTextField'

import * as styles from './PersonalInfo.module.css'

export const Name: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="firstName">
        <FormTextField
          name="firstName"
          label={t('FIRST_NAME')}
          maxLength={50}
          required
          rules={{
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="midName">
        <FormTextField
          name="middleName"
          label={t('MIDDLE_NAME')}
          helperText={t('OPTIONAL')}
          maxLength={50}
          rules={{
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="lastName">
        <FormTextField
          name="lastName"
          label={t('LAST_NAME')}
          maxLength={50}
          required
          rules={{
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="suffix">
        <FormTextField
          name="suffix"
          label={t('SUFFIX')}
          helperText={t('OPTIONAL')}
          maxLength={10}
          rules={{
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
        />
      </div>
    </>
  )
}
