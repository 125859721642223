import { useContext, useEffect, type FC } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddMemberContext, type DropdownValue, type DatePickerValue } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'
import { EligibleEmpGrp } from '../Employment/EligibleEmpGrp'
import { HireDateSelect } from '../Employment/HireDateSelect'
import { SignatureDate } from '../Employment/SignatureDate'

import * as styles from './Employment.module.css'

interface DefaultEmploymentValues {
  hireDate: DatePickerValue | undefined
  eligibleEmpGrp: DropdownValue | undefined
  signatureDate: DatePickerValue | undefined
}

const defaultEmploymentValues: DefaultEmploymentValues = {
  hireDate: undefined,
  eligibleEmpGrp: undefined,
  signatureDate: undefined,
}

export const Employment: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })
  const methods: UseFormReturn<DefaultEmploymentValues> = useForm<DefaultEmploymentValues>({
    mode: 'onChange',
    defaultValues: defaultEmploymentValues,
  })
  const { trigger, getValues, reset } = methods
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const { setStepHandler } = useContext(AddMemberProgressContext)
  const { setIsError } = useContext(AddMemberProgressContext)

  useEffect(() => {
    const onClickNext = async () => {
      const isValid = await trigger()
      if (!isValid) {
        setIsError(true)
      }
      if (isValid) {
        setIsError(false)
        const { hireDate, eligibleEmpGrp, signatureDate } = getValues()
        setEnrollmentInfo((enrollmentInfo) => ({
          ...enrollmentInfo,
          employmentInfo: { hireDate, eligibleEmpGrp, signatureDate },
        }))
      }
      return isValid
    }
    setStepHandler({ onClickNext })
  }, [getValues, trigger, setEnrollmentInfo, setStepHandler, setIsError])

  useEffect(() => {
    if (enrollmentInfo) {
      reset({ ...enrollmentInfo.employmentInfo, ...enrollmentInfo.benefitInfo, ...enrollmentInfo.memberGroups })
    }
  }, [enrollmentInfo, reset])

  return (
    <>
      <FormProvider {...methods}>
        <h3 className={styles.subtitle}>{t('ELIGIBLE_EMP_GRP')}</h3>
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <EligibleEmpGrp />
        </div>
        <h3 className={styles.subtitle}>{t('HIRE_DATE')}</h3>
        {/* <p className={styles.extraText}>{t('HIRE_DATE_TXT')}</p> */}
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <HireDateSelect />
        </div>
        <h3 className={styles.subtitle}>{t('SIG_DATE')}</h3>
        <p className={styles.extraText}>{t('SIG_DATE_TXT')}</p>
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <SignatureDate />
        </div>
      </FormProvider>
    </>
  )
}
