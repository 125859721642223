import { Button } from '@component-library/helios'
import { useState, type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload, useNavigate } from 'react-router-dom'
import type { Member_ListRequestQueryParams } from 'types/domain'

import { useGetFeatureToggles } from '../../../api/hooks/useGetFeatureToggles'
import { useGetMembers } from '../../../api/hooks/useGetMembers'
import { EmptyState } from '../../../components/MemberListTable/EmptyState'
import { MemberListTable } from '../../../components/MemberListTable/MemberListTable'
import { MemberSearch } from '../../../components/MemberSearch/MemberSearch'
import { PageContent } from '../../../components/PageContent/PageContent'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { QueryResultUnconditional } from '../../../components/QueryResult/QueryResultUnconditional'
import type { MemberListFilter } from '../../../context/MemberListFilterContext'
import { MemberListFilterContext } from '../../../context/MemberListFilterContext'
import { useDebounced } from '../../../hooks/useDebounced'
import { useMemberListPagination } from '../../../hooks/useMemberListPagination'
import { trackEvent } from '../../../utils/analytics'

import * as styles from './MemberList.module.css'

export const MemberList: FC = () => {
  const { data: featureToggles } = useGetFeatureToggles()
  // restore state of page from sessionStorage to support returning from the detail page
  const initMemberListPagination: Member_ListRequestQueryParams = JSON.parse(
    window.sessionStorage.getItem('memberListPaginaton') ||
      '{"page": 1, "pageSize": 25, "sortDirection": "asc", "sortKey": "name_full", "q": ""}',
  )
  const { sort, pagination, ...paginationCallbacks } = useMemberListPagination(initMemberListPagination)
  const [filter, setFilter] = useState<MemberListFilter>({})
  const [searchQuery, setSearchQuery] = useState(initMemberListPagination.q || '')

  const debouncedSearchQuery = useDebounced(searchQuery, 500)
  const { t } = useTranslation('translation', { keyPrefix: 'MemberList' })
  const navigate = useNavigate()
  const memberList = useGetMembers({
    query: {
      q: debouncedSearchQuery,
      ...sort,
      ...pagination,
    },
  })

  const emptyText = t('EMPTY_TEXT')
  const clearText = t('EMPTY_TEXT_ACTION')

  const handleSearchQueryChange = (query: string) => {
    //reset current page
    paginationCallbacks.onPageChange(1)
    setSearchQuery(query)
  }

  useEffect(() => {
    setFilter((prev) => ({ ...prev, q: debouncedSearchQuery }))
  }, [debouncedSearchQuery])

  useBeforeUnload(() => {
    // reset query search state on page unload
    window.sessionStorage.removeItem('memberListPaginaton')
  })

  const handleSearchClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'search bar click',
    })
  }

  const handleAddMemberClick = () => {
    navigate('/add-member')
  }

  return (
    <>
      <MemberListFilterContext.Provider value={filter}>
        <main>
          <PageContent>
            <div className={styles.titleCtr}>
              <PageTitle>{t('PAGE_TITLE')}</PageTitle>
              {featureToggles?.MEMBER_MGMT_ADD_MEMBER && (
                <div className={styles.addBtn}>
                  <Button onClick={handleAddMemberClick}>{t('ADD_MEMBER')}</Button>
                </div>
              )}
            </div>

            <div className="pb-2" onClick={handleSearchClick}>
              <MemberSearch
                label={t('SEARCH_PLACEHOLDER')}
                searchQuery={searchQuery}
                onSearchQueryChange={handleSearchQueryChange}
              />
            </div>

            <QueryResultUnconditional query={memberList}>
              {(data) => {
                return data?.pagination?.totalItems === 0 ? (
                  <EmptyState
                    emptyText={emptyText}
                    displayClear={true}
                    clearText={clearText}
                    clearFilter={() => {
                      handleSearchQueryChange('')
                    }}
                  />
                ) : (
                  <MemberListTable
                    members={data?.members ?? []}
                    totalItems={data?.pagination?.totalItems ?? 0}
                    {...pagination}
                    {...paginationCallbacks}
                  />
                )
              }}
            </QueryResultUnconditional>
          </PageContent>
        </main>
      </MemberListFilterContext.Provider>
    </>
  )
}
