import { Body, Button /*Divider*/ } from '@component-library/helios'
import { type FC, useContext } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { BenefitInfo } from 'types/domain'

import { AddMemberContext } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'
import { FormCheckbox } from '../../../uiComponents/Form/FormCheckbox'
import { FormRadioGroup } from '../../../uiComponents/Form/FormRadioGroup'

import * as styles from './Benefits.module.css'
import { TieredCoverage } from './TieredCoverage'
import { VolumeBasedCoverage } from './VolumeBasedCoverage'

type BenefitListDetailProps = {
  benefitInfo: BenefitInfo
  setCardOpen?: (cardOpen: boolean) => void
}

export const BenefitListCardDetail: FC<BenefitListDetailProps> = ({
  benefitInfo,
  setCardOpen = () => {},
}: BenefitListDetailProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const methods: UseFormReturn<BenefitInfo> = useForm<BenefitInfo>({
    mode: 'onChange',
    defaultValues: {
      electedTier: benefitInfo.electedTier,
      electedBuyUp: benefitInfo.electedBuyUp,
      electedPlan: benefitInfo.electedPlan,
    },
  })
  const { trigger, getValues } = methods
  const { setIsError } = useContext(AddMemberProgressContext)

  const getBenefitInfo = (benefitId: number) => {
    return enrollmentInfo!.benefitInfo!.filter((benefitInfo) => benefitInfo.id !== benefitId)
  }

  const onClickSave = async () => {
    const isValid = await trigger()
    if (!isValid) {
      setIsError(true)
    }
    if (isValid) {
      setIsError(false)
      const { electedTier, electedBuyUp, electedPlan } = getValues()
      const updatedBenefitInfo: BenefitInfo = {
        ...benefitInfo,
        electedTier: electedTier,
        electedBuyUp: electedBuyUp,
        electedPlan: electedPlan,
        elected: true,
      }
      setEnrollmentInfo((enrollmentInfo) => ({
        ...enrollmentInfo,
        benefitInfo: [...getBenefitInfo(benefitInfo.id), updatedBenefitInfo],
      }))
      setCardOpen(false)
    }
    return isValid
  }

  return (
    <>
      {/* {false && (
        <div className="mt-3 mb-3">
          <Body variant="medium-bold">Eligibility requirements</Body>
          <ul>
            <li className="mt-2">Minimum 30 hours worked per week</li>
            <li className="mt-2">Maximum dependent age is 26, but it increases to 35 for a full-time student.</li>
          </ul>
        </div>
      )} */}
      <div className="mt-4 mb-3">
        <FormProvider {...methods}>
          {benefitInfo.plans.length > 0 && (
            <>
              <Body variant="medium-bold">{t('PLAN_SECTION')}</Body>
              <FormRadioGroup name="electedPlan" required options={benefitInfo.plans} />
            </>
          )}
          <Body variant="medium-bold">Select coverage</Body>

          {benefitInfo.volumeBased ? (
            <VolumeBasedCoverage tiers={benefitInfo.tiers} />
          ) : (
            <TieredCoverage tiers={benefitInfo.tiers} />
          )}

          {benefitInfo.rules.hasBuyUp && (
            <>
              <Body variant="medium-bold">{t('BUY_UP_SECTION')}</Body>
              <FormCheckbox name="electedBuyUp" label={t('BUY_UP_LABEL')} helperText={t('BUY_UP_HELPER_TEXT')} />
            </>
          )}
        </FormProvider>
      </div>
      <div className={styles.benefitCardActions}>
        <Button
          variant="secondary"
          onClick={() => {
            setCardOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onClickSave()}>
          Save
        </Button>
      </div>
      {/* <div className="mt-3">
        <Divider orientation="horizontal" size="sm" />
        {false && <p>Anticipated effective date Jun. 1, 2024.</p>}
        {false && <p>Dental ID card available immediately after enrollment.</p>}
      </div> */}
    </>
  )
}
