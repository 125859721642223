import { set } from 'lodash'
import { useState, type FC, useEffect } from 'react'
import type { BenefitInfo } from 'types/domain'

import { BenefitListCardDetail } from './BenefitListCardDetail'
import { BenefitListCardElected } from './BenefitListCardElected'
import { BenefitListCardHeader } from './BenefitListCardHeader'
import * as styles from './Benefits.module.css'

type BenefitListCardProps = {
  benefitInfo: BenefitInfo
  isAnyCardOpen?: boolean
  withActions?: boolean
  setIsAnyCardOpen?: (setIsAnyCardOpen: boolean) => void
}

export const BenefitListCard: FC<BenefitListCardProps> = ({
  benefitInfo,
  isAnyCardOpen = false,
  withActions = true,
  setIsAnyCardOpen = () => {},
}: BenefitListCardProps) => {
  const [isCardOpen, setCardOpen] = useState(false)

  const setCardOpenHandler = (cardOpen: boolean) => {
    setCardOpen(cardOpen)
    cardOpen ? setIsAnyCardOpen(true) : setIsAnyCardOpen(false)
  }
  const disabled = !isCardOpen && isAnyCardOpen ? true : false

  return (
    <>
      <div className={styles.benefitCard} style={disabled ? { backgroundColor: '#F9FAFA' } : {}}>
        <BenefitListCardHeader
          benefitInfo={benefitInfo}
          setCardOpen={setCardOpenHandler}
          cardOpen={isCardOpen}
          withActions={withActions}
          isAnyCardOpen={isAnyCardOpen}
        />
        {!isCardOpen && benefitInfo.elected && <BenefitListCardElected benefitInfo={benefitInfo} />}
        {isCardOpen && <BenefitListCardDetail benefitInfo={benefitInfo} setCardOpen={setCardOpenHandler} />}
      </div>
    </>
  )
}
