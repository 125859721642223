import { Body, MenuDropdown, Table } from '@component-library/helios'
import { useState, type FC, type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { type DependentInfo } from '../../../../app/context/AddMemberContext'

import { formatDateShortMonthWithYear, formatFullName, formatSSN, getAge } from './../../../../app/utils/format'
import * as styles from './Dependents.module.css'

export type DependentsTableProps = {
  variant: 'spouse' | 'child'
  dependents: DependentInfo[]
  actions?: (dependent: DependentInfo) => ComponentProps<typeof MenuDropdown>['data']['children']
}

export const DependentsTable: FC<DependentsTableProps> = ({ actions, dependents, variant }: DependentsTableProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Dependents' })
  const [actionsClicked, setActionsClicked] = useState(false)

  return (
    <Table
      className={styles.dependentsTable}
      columns={[
        {
          field: `${variant}Name`,
          title: 'Name',
          align: 'left',
          width: '30%',
        },
        {
          field: `${variant}DateOfBirth`,
          title: 'Date of Birth',
          align: 'left',
          width: '20%',
        },
        {
          field: `${variant}Ssn`,
          title: 'SSN',
          align: 'left',
          width: '20%',
        },
        {
          field: `${variant}Gender`,
          title: 'Gender',
          align: 'left',
          width: '20%',
        },
        ...(actions
          ? [
              {
                field: `${variant}Actions`,
                title: 'Actions',
                align: 'right' as const,
                width: '10%',
              },
            ]
          : []),
      ]}
      rows={dependents.map((dependent) => ({
        [`${variant}Name`]: formatFullName(dependent),
        [`${variant}DateOfBirth`]: dependent.dateOfBirth?.selected ? (
          <>
            {/* e2e tests depend on the space between the date and the age */}
            <Body variant="small">{formatDateShortMonthWithYear(dependent.dateOfBirth.selected)}</Body> <br />
            <Body variant="small" color="caption">
              {t('YEARS_OLD', { count: getAge(dependent.dateOfBirth.selected) })}
            </Body>
          </>
        ) : (
          '-'
        ),
        [`${variant}Ssn`]: formatSSN(dependent.ssn),
        [`${variant}Gender`]: t('MEMBERGENDER.' + dependent.genderCode),
        [`${variant}Actions`]: actions ? (
          <MenuDropdown
            onClick={() => setActionsClicked(!actionsClicked)}
            toggleClass={styles.dropdownIcon}
            data={{
              children: actions(dependent),
            }}
            dropdownIcon={<i className="far fa-ellipsis-v" aria-hidden="true" />}
          />
        ) : null,
      }))}
    />
  )
}
