import { Progress } from '@component-library/helios'
import { useCallback, useEffect, useState, type PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import type { User } from 'types/domain'

import { instance } from '../../api/axios'
import { useAuthLogout } from '../../api/hooks/useAuthLogout'
import { usePostAuth } from '../../api/hooks/usePostAuth'
import { usePostExtendAuth } from '../../api/hooks/usePostExtendAuth'
import { useAuthToken } from '../../hooks/useAuthToken'
import { SESSION_EXPIRATION_TIMEOUT_IN_MINUTES } from '../../utils/getAuthTokenActivity'
import {
  getAuthCookieDomain,
  getAuthServiceTokenName,
  getConfigForSLCHomeUrl,
  isLocal,
} from '../../utils/getClientConfig'

import { AuthContext } from './AuthContext'
import { AuthMonitor } from './AuthMonitor'

const logoutPathname = '/logout'
const pathnamesWhitelist = [logoutPathname]

export function AuthProvider({ children }: PropsWithChildren) {
  const SLCHomePageUrl = getConfigForSLCHomeUrl()

  const { pathname } = useLocation()
  const [user, setUser] = useState<User>()
  const [authToken, setAuthToken] = useAuthToken()
  const logout = useLogout()
  const [authEnabled, setAuthEnabled] = useState(false)
  const [customHeadersAvailable, setCustomHeadersAvailable] = useState(false)
  const auth = usePostAuth({ body: { token: authToken.token } }, { enabled: authEnabled })
  const extend = usePostExtendAuth({ body: { token: authToken?.token } }, { enabled: false })

  const setCustomHeaders = useCallback((user: User) => {
    instance.defaults.headers.common['X-EmployerID'] = user?.employerId
    instance.defaults.headers.common['X-Username'] = user?.username
    instance.defaults.headers.common['X-SponsorID'] = user?.metadata?.sponsorId
    if (user?.metadata?.impersonatorId) {
      instance.defaults.headers.common['X-ImpersonatorID'] = user.metadata.impersonatorId
    }
    setCustomHeadersAvailable(true)
  }, [])

  const setAuthTokenInCookie = useCallback((user: User) => {
    if (user?.token) {
      // Set the token in the browser's cookies
      const date = new Date()
      date.setTime(date.getTime() + SESSION_EXPIRATION_TIMEOUT_IN_MINUTES * 60 * 1000) // Convert minutes to ms
      const expires = 'expires=' + date.toUTCString()
      document.cookie = `${getAuthServiceTokenName()}=${user.token}; path=/; domain=${getAuthCookieDomain()}; ${
        !isLocal ? 'secure;' : ''
      } samesite=strict; ${expires};`
    }
  }, [])

  const setAnalyticsUserInfo = useCallback((user: User) => {
    if (window.utag_data) {
      window.utag_data.user_id = user?.participantId ?? 'anonymous'
      window.utag_data.user_role = user?.metadata?.sponsorContactRole ?? 'unknown'
      if (user?.metadata?.impersonatorId) window.utag_data.user_impersonation_id = user.metadata.impersonatorId
    }
  }, [])

  useEffect(() => {
    const isLogoutPathname = pathname === logoutPathname
    const isRedirect = !!document.referrer
    const isRedirectFromLogout = isLogoutPathname && isRedirect
    if (!authEnabled && !isRedirectFromLogout) {
      setAuthEnabled(true)
    }
  }, [authEnabled, pathname])

  // If authentication/authorization succeed, set user.
  useEffect(() => {
    if (auth.isSuccess) {
      if (auth.data.status === 'authenticated') {
        const user = auth.data.user
        setCustomHeaders(user)
        setUser(user)
        setAuthTokenInCookie(user)
        setAnalyticsUserInfo(user)
      }
    }
  }, [auth.data?.status, auth.data?.user, auth.isSuccess, setCustomHeaders, setAnalyticsUserInfo, setAuthTokenInCookie])

  // If session extension succeed, set user.
  useEffect(() => {
    if (extend.isSuccess) {
      if (extend.data.status === 'authenticated') {
        const user = extend.data.user
        setCustomHeaders(user)
        setUser(user)
        setAuthTokenInCookie(user)
      }
    }
  }, [extend.data?.status, extend.data?.user, extend.isSuccess, setCustomHeaders, setAuthTokenInCookie])

  // Store the auth token when the user is set.
  useEffect(() => {
    if (user) {
      setAuthToken({
        token: user.token,
        createdAt: user.createdAt,
      })
    }
  }, [setAuthToken, user])

  // If authentication fails redirect to login.
  useEffect(() => {
    const isError = auth.isError
    const isUnauthenticated = auth.data?.status === 'unauthenticated' || extend.data?.status === 'unauthenticated'
    const isPathnameWhitelisted = pathnamesWhitelist.includes(pathname)

    if ((isError || isUnauthenticated) && !isPathnameWhitelisted) {
      window.location.replace(`${SLCHomePageUrl}`)
    }
  }, [auth.data?.status, auth.isError, SLCHomePageUrl, extend.data?.status, pathname])

  // Render contents when user is authenticated or the current path is whitelisted.
  const isAuthenticated = auth.data?.status === 'authenticated' || extend.data?.status === 'authenticated'
  const isPathnameWhitelisted = pathnamesWhitelist.includes(pathname)
  const hasAuthToken = Boolean(authToken.token)

  if ((isAuthenticated && hasAuthToken && customHeadersAvailable) || isPathnameWhitelisted) {
    return (
      <AuthContext.Provider value={{ user, authToken, logout }}>
        {!isPathnameWhitelisted && <AuthMonitor />}
        {children}
      </AuthContext.Provider>
    )
  }

  // Otherwise render indeterminate progress bar.
  return <Progress id="authProviderLoading" label="" variant="indeterminate" />
}

const useLogout = () => {
  const [{ token }, setAuthToken] = useAuthToken()
  const { isSuccess, refetch } = useAuthLogout({ body: { token } }, { enabled: false })

  const logout = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (isSuccess) {
      setAuthToken({ token: '', createdAt: 0 })
      location.href = '/logout' // Full refresh needed to clear httpOnly session cookie.
    }
  }, [isSuccess, setAuthToken])

  return logout
}
