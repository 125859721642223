import { Drawer } from '@component-library/helios'
import { useEffect, type FC, useContext, useState, useRef } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DependentRelationship } from '../../../../types/enums'
import type { DependentInfo } from '../../../context/AddMemberContext'
import { AddMemberContext } from '../../../context/AddMemberContext'
import { ErrorNotification } from '../../ErrorNotification/ErrorNotification'
import { DateOfBirth } from '../MemberInfo/PersonalInfo/DateOfBirth'
import { Gender } from '../MemberInfo/PersonalInfo/Gender'
import { Name } from '../MemberInfo/PersonalInfo/Name'
import { Ssn } from '../MemberInfo/PersonalInfo/Ssn'

const defaultDependentValues: DependentInfo = {
  id: 0,
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  dateOfBirth: undefined,
  genderCode: '',
  ssn: '',
  relationship: DependentRelationship.UNKNOWN,
}

type DependentsDrawerProps = {
  showDrawer: boolean
  relationship: DependentRelationship
  editDependentId?: number
  setShowDrawer: (showDrawer: boolean) => void
}

export const DependentsDrawer: FC<DependentsDrawerProps> = ({
  showDrawer,
  setShowDrawer,
  relationship,
  editDependentId,
}: DependentsDrawerProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Dependents' })
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const methods: UseFormReturn<DependentInfo> = useForm<DependentInfo>({
    mode: 'onChange',
    defaultValues: defaultDependentValues,
  })
  const { trigger, getValues, reset } = methods
  const [isError, setIsError] = useState(false)
  // Create a ref for the current component to scroll to the top of the page
  const myRef = useRef<HTMLDivElement>(null)

  // Check if the SSN is a duplicate of any other SSN in the enrollmentInfo
  const duplicateSSN = (ssn: string) => {
    return (
      enrollmentInfo.dependentInfo.some((dependent) => dependent.ssn === ssn) || enrollmentInfo.personalInfo.ssn === ssn
    )
  }

  const onClickSave = async () => {
    const isValid = await trigger()
    if (!isValid) {
      setIsError(true)
      myRef.current && myRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (isValid) {
      setIsError(false)
      const { firstName, middleName, lastName, suffix, dateOfBirth, genderCode, ssn } = getValues()
      //determine the max dependentId to assign to the new dependent
      const maxDependentId =
        enrollmentInfo.dependentInfo.length > 0
          ? enrollmentInfo.dependentInfo.reduce((prev, current) => {
              return prev && prev.id > current.id ? prev : current
            }).id
          : 1
      //set the id for the new dependent
      const id = editDependentId ? editDependentId : maxDependentId + 1
      //update the dependentInfo array with the new dependent
      const dependentInfo: DependentInfo[] = editDependentId
        ? enrollmentInfo.dependentInfo.map((dependent) =>
            dependent.id === editDependentId
              ? { id, firstName, middleName, lastName, suffix, dateOfBirth, genderCode, ssn, relationship }
              : dependent,
          )
        : [
            ...enrollmentInfo.dependentInfo,
            { id, firstName, middleName, lastName, suffix, dateOfBirth, genderCode, ssn, relationship },
          ]

      setEnrollmentInfo((enrollmentInfo) => ({
        ...enrollmentInfo,
        dependentInfo: dependentInfo,
      }))
      reset()
      setIsError(false)
      setShowDrawer(!showDrawer)
    }
    return isValid
  }

  useEffect(() => {
    if (editDependentId) {
      const dependent = enrollmentInfo.dependentInfo.find((dependent) => dependent.id === editDependentId)
      reset(dependent)
    } else {
      reset({})
    }
  }, [enrollmentInfo, reset, editDependentId])

  return (
    <>
      <Drawer
        show={showDrawer}
        size="small"
        actions={[
          {
            children: 'Save',
            id: '2',
            variant: 'primary',
            onClick: async () => {
              onClickSave()
            },
          },
        ]}
        closeLabel="Close Label"
        heading={'Add ' + t('RELATIONSHIP.' + relationship).toLowerCase()}
        onExit={() => {}}
        onExited={() => {
          reset({})
          setIsError(false)
          setShowDrawer(false)
        }}
      >
        <div ref={myRef}>
          {isError && <ErrorNotification errorMsg={t('ERROR_NOTIF')} />}
          <FormProvider {...methods}>
            {/* {methods.formState.errors && <Notification variant="danger">Please see errors below</Notification>} */}
            <div>
              <Name />
              {relationship === DependentRelationship.DEPENDENT ? (
                <DateOfBirth maxAge={40} minAge={0} />
              ) : (
                <DateOfBirth />
              )}
              <Gender inline={false} />
              <Ssn isDuplicateSSN={duplicateSSN} />
            </div>
          </FormProvider>
        </div>
      </Drawer>
    </>
  )
}
