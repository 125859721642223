import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { MemberDetails } from 'types/domain'

import { DataDetails } from '../../uiComponents/DataDetails/DataDetails'
import { currencyFormatter, formatDateFullMonthWithYear, getAge } from '../../utils/format'

type MemberInfoCardProps = {
  member: MemberDetails
}

export const MemberInfoCard: FC<MemberInfoCardProps> = ({ member }: MemberInfoCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'MemberDetail' })
  const smoker = { display: true }
  const dentalId = { display: true }
  const prefLang = { display: true }

  //Certain labels are shown only if data is avail
  member.smoker == '' ||
  member.smoker == 'UNKNOWN' ||
  member.smoker == 'SMOKER_UNKNOWN' ||
  member.smoker == 'NOT_APPLICABLE'
    ? (smoker.display = false)
    : smoker.display
  member.preferredLanguage == '' ? (prefLang.display = false) : prefLang.display
  !member.memberFacilities || member.memberFacilities.length === 0 ? (dentalId.display = false) : dentalId.display

  const formatDateOfBirth = member.dateOfBirth
    ? formatDateFullMonthWithYear(member.dateOfBirth) + ` (${getAge(member.dateOfBirth)} ${t('AGE_SUFFIX')})`
    : ''

  const formatDOH = member.employment?.dateOfHire ? formatDateFullMonthWithYear(member.employment.dateOfHire) : ''

  //Salary formatting
  const formattedSalary = (member: MemberDetails) => {
    if (!member.employment) {
      return ''
    }
    const salary = member.employment?.salary ? currencyFormatter(member.employment?.salary) : ''
    const formatSalaryEffectiveDate = member.employment?.salaryEffectiveDate
      ? formatDateFullMonthWithYear(member.employment.salaryEffectiveDate)
      : ''
    const salaryEffective = t('EFFECTIVE') + ' ' + formatSalaryEffectiveDate + ', '
    const salaryFrequency =
      member.employment.salaryFrequency && member.employment.salaryFrequency != 'UNKNOWN'
        ? `${t('PAID_FREQUENCY')} ${t('SALARY_FREQUENCY.' + member.employment.salaryFrequency)}`
        : ''

    return salary + ' ' + salaryEffective + salaryFrequency
  }

  //SSN formatting
  const formattedSSN = member.lastFourSSN ? '***-**-' + member.lastFourSSN : ''

  //Smoker Logic
  let formatSmoker = ''

  if (member.smoker === 'SMOKER') {
    formatSmoker = t('YES')
  } else if (member.smoker === 'NON_SMOKER') {
    formatSmoker = t('NO')
  }

  // format the address for display, only include values that exist and comma separate
  const formatAddress = [
    member.address.line1,
    member.address.line2,
    member.address.city,
    member.address.state,
    member.address.zip,
    member.address.country,
  ]
    .filter((addressAttrib) => addressAttrib)
    .join(', ')

  let memberFacilityID = ''
  if (member.memberFacilities && member.memberFacilities.length) {
    memberFacilityID = member.memberFacilities
      .map((facility) => facility.facilityId)
      .filter((facilityAttrib) => facilityAttrib)
      .join(', ')
  }

  return (
    <div className="row">
      <div className="col-md-6">
        <DataDetails
          data={[
            { label: t('MEMBER_DOB'), value: formatDateOfBirth },
            { label: t('MEMBER_SSN'), value: formattedSSN },
            { label: t('MEMBER_GENDER'), value: member.gender ? t('MEMBERGENDER.' + member.gender) : null },
            { label: t('MEMBER_ADDRESS'), value: formatAddress },
            ...(prefLang.display
              ? [
                  {
                    label: t('MEMBER_PREF_LANG'),
                    value: member.preferredLanguage ? t('PREF_LANGUAGE.' + member.preferredLanguage) : null,
                  },
                ]
              : []),
            ...(smoker.display ? [{ label: t('MEMBER_SMOKER'), value: formatSmoker }] : []),
            ...(dentalId.display ? [{ label: t('MEMBER_DENTAL_ID'), value: memberFacilityID }] : []),
          ]}
        />
      </div>
      <div className="col-md-6">
        <DataDetails
          data={[
            { label: t('MEMBER_DOH'), value: formatDOH },
            { label: t('MEMBER_SALARY'), value: formattedSalary(member) },
          ]}
        />
      </div>
    </div>
  )
}
