import { useContext, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { TierInfo } from 'types/domain'

import { BenefitTier } from '../../../../types/enums'
import { AddMemberContext } from '../../../context/AddMemberContext'
import { FormRadioGroup } from '../../../uiComponents/Form/FormRadioGroup'

interface TieredCoverageProps {
  tiers: TierInfo[]
}

export const TieredCoverage: FC<TieredCoverageProps> = ({ tiers }: TieredCoverageProps) => {
  const { enrollmentInfo } = useContext(AddMemberContext)
  const memberName = enrollmentInfo?.personalInfo.firstName
  const spouseName = enrollmentInfo?.dependentInfo.filter((dependent) => dependent.relationship === 'SPOUSE')[0]
    ?.firstName
  const childrenNames = enrollmentInfo?.dependentInfo
    .filter((dependent) => dependent.relationship === 'DEPENDENT')
    .map((dependent) => dependent.firstName)
    .join(', ')
  const getCoveredText = (tier: TierInfo) => {
    const coveredText = `Covers ${memberName} ${
      tier.covers.includes(BenefitTier.FAMILY)
        ? ', Spouse / domestic partner ' + (spouseName ? '(' + spouseName + ')' : '')
        : ''
    }, and unlimited children ${childrenNames ? '(' + childrenNames + ')' : ''}.`
    return tier.covers.includes(BenefitTier.CHILD) || tier.covers.includes(BenefitTier.FAMILY) ? coveredText : ''
  }
  // , ${tier.covers.contains('spouse') / domestic partner${
  //   spouseName ? spouseName : ''
  // }, and unlimited children ${childrenNames ? '(' + childrenNames + ')' : ''}.`)
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })

  return (
    <div data-cy="radio_tiered_coverage_group" data-testid="tieredCoverage">
      <FormRadioGroup
        name="electedTier"
        options={tiers.map((tier) => ({
          value: tier.tier,
          label: t(tier.tier),
          helperText: tier.covers ? getCoveredText(tier) : undefined,
        }))}
        required
      />
    </div>
  )
}
