import { set } from 'lodash'
import { useContext, useEffect, useState, type FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// import type { BenefitInfo } from '../../../context/AddMemberContext'
import type { BenefitInfo } from 'types/domain'

import { AddMemberContext, getMemberGroupBenefits } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

import { BenefitListCard } from './BenefitListCard'

export const Benefits: FC = () => {
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const { setStepHandler, setIsError, setErrorMsg } = useContext(AddMemberProgressContext)
  const [isAnyCardOpen, setIsAnyCardOpen] = useState(false)
  const [filteredBenefits, setFilteredBenefits] = useState<BenefitInfo[] | undefined>()
  const missingBenefitsError = t('MISSING_BENEFIT_ELECTION')
  const noBenefitsError = t('NO_BENEFIT_ELECTED')

  const isValid = useCallback(() => {
    if (filteredBenefits?.length !== filteredBenefits?.filter((benefit) => benefit.elected || benefit.waived).length) {
      setErrorMsg(missingBenefitsError)
      return false
    }

    if (!(filteredBenefits && filteredBenefits?.filter((benefit) => benefit.elected).length > 0)) {
      setErrorMsg(noBenefitsError)
      return false
    }
    setErrorMsg('')
    return true
  }, [filteredBenefits, missingBenefitsError, noBenefitsError, setErrorMsg])

  useEffect(() => {
    const onClickNext = async () => {
      //is valid if all benefits are elected or waived and at least one benefit is elected
      const valid = isValid()
      if (!valid) {
        setIsError(true)
      }
      if (valid) {
        setIsError(false)
      }
      return valid
    }
    setStepHandler({ onClickNext })
  }, [setStepHandler, setIsError, isValid])

  useEffect(() => {
    setFilteredBenefits(getMemberGroupBenefits(enrollmentInfo, enrollmentInfo?.employmentInfo?.eligibleEmpGrp?.value))
  }, [enrollmentInfo])

  return (
    <>
      {!filteredBenefits?.length && (
        <div>
          <span>No benefits available.</span>
        </div>
      )}

      {filteredBenefits?.map((benefitInfo) => (
        <BenefitListCard
          key={benefitInfo.benefitGroup}
          benefitInfo={benefitInfo}
          isAnyCardOpen={isAnyCardOpen}
          setIsAnyCardOpen={setIsAnyCardOpen}
        />
      ))}
    </>
  )
}
