import type { DetailBenefitListItem, BenefitListItem } from 'types/domain'

import { BenefitCategory, BenefitStatus } from '../../types/enums'

export function hasDentalCoverage(benefits: DetailBenefitListItem[] | BenefitListItem[] = []) {
  return (benefits ?? []).some(
    ({ category, status }) =>
      category === BenefitCategory.DENTAL &&
      (status == BenefitStatus.APPROVED_ACTIVE || status == BenefitStatus.TERMINATING_SOON),
  )
}
