import { GuidedExperience } from '@component-library/helios'
import { type FC, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Benefits } from '../../../components/AddMember/Benefits/Benefits'
import { Billing } from '../../../components/AddMember/Billing/Billing'
import { Dependents } from '../../../components/AddMember/Dependents/Dependents'
import { Employment } from '../../../components/AddMember/Employment/Employment'
import { MemberInfo } from '../../../components/AddMember/MemberInfo/MemberInfo'
import { Review } from '../../../components/AddMember/Review/Review'
import {
  type AddMemberGuidedStepStatus,
  type AddMemberStepId,
  useGuidedStepId,
  type AddMemberGuidedStep,
} from '../../../components/AddMember/hooks'
import { ErrorNotification } from '../../../components/ErrorNotification/ErrorNotification'
import { AddMemberProvider } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

export const AddMember: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'AddMember' })
  const navigate = useNavigate()
  const [stepHandler, setStepHandler] = useState({ onClickNext: async () => true })
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [activeGuidedStepId, setActiveGuidedStepId] = useGuidedStepId()
  const headerRef = useRef(null)

  //Guided Experience
  const GUIDED_EXPERIENCE_STATUS = {
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    STARTED: 'STARTED',
  } as const
  const { COMPLETED, NOT_STARTED, STARTED } = GUIDED_EXPERIENCE_STATUS

  const handleGoToStep = (stepId: AddMemberStepId) => {
    window.scrollTo(0, 0)
    setActiveGuidedStepId(stepId)
  }

  const [steps, setSteps] = useState<AddMemberGuidedStep[]>([
    {
      id: 'member_info',
      label: `${t('MEMBER_INFO')}`,
      status: NOT_STARTED,
      title: `${t('MEMBER_INFO')}`,
      content: <MemberInfo />,
      isStepRequired: () => true,
    },
    {
      id: 'employment',
      label: `${t('EMPLOYMENT')}`,
      status: NOT_STARTED,
      title: `${t('EMPLOYMENT')}`,
      content: <Employment />,
      isStepRequired: () => true,
    },
    {
      id: 'dependents',
      label: `${t('DEPENDENTS')}`,
      status: NOT_STARTED,
      title: `${t('DEPENDENTS')}`,
      content: <Dependents />,
      isStepRequired: () => true,
    },
    {
      id: 'benefits',
      label: `${t('BENEFITS')}`,
      status: NOT_STARTED,
      title: `${t('BENEFITS')}`,
      content: <Benefits />,
      isStepRequired: () => true,
    },
    {
      id: 'billing',
      label: `${t('BILLING')}`,
      status: NOT_STARTED,
      title: `${t('BILLING')}`,
      content: <Billing />,
      isStepRequired: () => true,
    },
    {
      id: 'review',
      label: `${t('REVIEW')}`,
      status: NOT_STARTED,
      title: `${t('REVIEW')}`,
      content: <Review onStepChange={handleGoToStep} />,
      isStepRequired: () => true,
    },
  ])

  const handleOnClickNext = async () => {
    window.scrollTo(0, 0)
    const isValid = await stepHandler.onClickNext()
    if (isValid) {
      setSteps((steps) => steps.map((step) => (step.id === activeGuidedStepId ? { ...step, status: COMPLETED } : step)))

      // calculate the next step index, be sure not to exceed the new workingSteps array length
      const activeStepIndex = steps.findIndex((step) => step.id === activeGuidedStepId)
      const nextStepIndex = Math.min(steps.length - 1, activeStepIndex + 1)

      // set the next step index as the active step
      setActiveGuidedStepId(steps[nextStepIndex].id)
    }
  }

  const handleOnClickPrevious = async () => {
    window.scrollTo(0, 0)

    setIsError(false)
    setErrorMsg('')
    const activeStepIndex = steps.findIndex((step) => step.id === activeGuidedStepId)
    const previouseStepIndex = Math.max(0, activeStepIndex - 1)
    setActiveGuidedStepId(steps[previouseStepIndex].id)
  }

  useEffect(() => {
    const activeStep = steps.find((step) => step.id === activeGuidedStepId)
    const setStepStatusAtIndex = (stepId: AddMemberStepId, status: AddMemberGuidedStepStatus) => {
      setSteps(steps.map((step) => (step.id === stepId ? { ...step, status } : step)))
    }
    if (activeStep?.status === NOT_STARTED) {
      setStepStatusAtIndex(activeGuidedStepId, STARTED)
    }
  }, [steps, activeGuidedStepId, NOT_STARTED, STARTED])

  const nextLabel = activeGuidedStepId === 'review' ? t('COMPLETE') : t('NEXT')

  return (
    <>
      <AddMemberProvider>
        <AddMemberProgressContext.Provider
          value={{
            stepHandler,
            setStepHandler,
            isError,
            setIsError,
            errorMsg,
            setErrorMsg,
          }}
        >
          {isError ? <ErrorNotification errorMsg={errorMsg} /> : ''}
          <GuidedExperience
            action={{
              next: {
                children: nextLabel,
                show: true,
                variant: 'primary',
                onClick: handleOnClickNext,
              },
              previous: {
                children: t('BACK'),
                show: steps.findIndex(({ id }) => id === activeGuidedStepId) !== 0,
                variant: 'secondary',
                onClick: handleOnClickPrevious,
              },
              cancel: {
                children: t('CANCEL'),
                variant: 'tertiary',
                onClick: () => {
                  window.scrollTo(0, 0)
                  navigate('/members')
                },
                show: activeGuidedStepId !== 'review',
              },
            }}
            activeStep={activeGuidedStepId}
            heading={t('ADD_MEMBER')}
            onStepClick={(activeStep: string, id: AddMemberStepId) => setActiveGuidedStepId(id)}
            showMenuLabel={t('STEP_MENU')}
            stickyRef={headerRef}
            stepsWidth={null}
            steps={steps}
            progressLabel=""
            stepLabel={t('STEP_OF', {
              currentStep: steps.findIndex(({ id }) => id === activeGuidedStepId) + 1,
              totalSteps: steps.length,
            })}
            // stepMenuLabel=""
          >
            {steps.find(({ id }) => id === activeGuidedStepId)?.content || null}
          </GuidedExperience>
        </AddMemberProgressContext.Provider>
      </AddMemberProvider>
    </>
  )
}
