import { type FC, useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddMemberContext } from '../../../context/AddMemberContext'
import { FormDropdownField } from '../../../uiComponents/Form/FormDropdownField'

import * as styles from './Billing.module.css'

export const Department: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Billing' })
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { watch, setValue, getValues } = useFormContext()

  const billGroup = watch('billLocation')
  const currentBillDepartment = getValues('billDepartment')

  const filteredDepartments = enrollmentInfo.departments?.filter(
    (department) => department.billGroupID === billGroup?.value,
  )

  useEffect(() => {
    if (
      currentBillDepartment &&
      !filteredDepartments?.some((department) => department.id === currentBillDepartment.value)
    ) {
      setValue('billDepartment', null)
    }
  }, [setValue, billGroup, currentBillDepartment, filteredDepartments])

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="billDepartment">
        <FormDropdownField
          name="billDepartment"
          label={t('DEPARTMENT')}
          helperText={t('DEPARTMENT_TXT')}
          options={
            filteredDepartments?.map((billDepartment) => ({
              label: billDepartment.name,
              value: billDepartment.id,
            })) ?? []
          }
          required
        />
      </div>
    </>
  )
}
