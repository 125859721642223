import { useContext, useEffect, type FC, useCallback } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddMemberContext, type DatePickerValue, type DropdownValue } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'
import { Email } from '../../AddMember/MemberInfo/Contact/Email'
import { Phone } from '../../AddMember/MemberInfo/Contact/Phone'
import { Address } from '../MemberInfo/MailingAddress/Address'
import { City } from '../MemberInfo/MailingAddress/City'
import { Country } from '../MemberInfo/MailingAddress/Country'
import { State } from '../MemberInfo/MailingAddress/State'
import { Zip } from '../MemberInfo/MailingAddress/Zip'
import { DateOfBirth } from '../MemberInfo/PersonalInfo/DateOfBirth'
import { Gender } from '../MemberInfo/PersonalInfo/Gender'
import { Name } from '../MemberInfo/PersonalInfo/Name'
import { Ssn } from '../MemberInfo/PersonalInfo/Ssn'

import * as styles from './MemberInfo.module.css'

interface DefaultMemberValues {
  firstName: string
  middleName: string
  lastName: string
  suffix: string
  dateOfBirth: DatePickerValue | undefined
  genderCode: string
  ssn: string
  country: DropdownValue | undefined
  address: string
  address2: string
  city: string
  state: DropdownValue | undefined
  zip: string
  email: string
  phoneNum: string
  phoneType: DropdownValue | undefined
}

const defaultMemberValues: DefaultMemberValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  dateOfBirth: undefined,
  genderCode: '',
  ssn: '',
  country: undefined,
  address: '',
  address2: '',
  city: '',
  state: undefined,
  zip: '',
  email: '',
  phoneNum: '',
  phoneType: undefined,
}

export const MemberInfo: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })
  const methods: UseFormReturn<DefaultMemberValues> = useForm<DefaultMemberValues>({
    mode: 'onChange',
    defaultValues: defaultMemberValues,
  })
  const { trigger, getValues, resetField, reset, watch } = methods
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const { setStepHandler } = useContext(AddMemberProgressContext)
  const { setIsError } = useContext(AddMemberProgressContext)
  const country = watch('country')
  const isCanada = country?.label === 'Canada'

  const resetAddressFields = useCallback(() => {
    resetField('address')
    resetField('address2')
    resetField('city')
    resetField('state')
    resetField('zip')
  }, [resetField])

  useEffect(() => {
    if (country) {
      resetAddressFields()
    }
  }, [country, resetAddressFields])

  useEffect(() => {
    const onClickNext = async () => {
      const isValid = await trigger()
      if (!isValid) {
        setIsError(true)
      }
      if (isValid) {
        setIsError(false)
        const {
          firstName,
          middleName,
          lastName,
          suffix,
          dateOfBirth,
          genderCode,
          ssn,
          country,
          address,
          address2,
          city,
          state,
          zip,
          email,
          phoneNum,
          phoneType,
        } = getValues()

        setEnrollmentInfo((enrollmentInfo) => ({
          ...enrollmentInfo,
          personalInfo: { firstName, middleName, lastName, suffix, dateOfBirth, genderCode, ssn },
          mailingAddress: { country, address, address2, city, state, zip },
          contactInfo: { email, phoneNum, phoneType },
        }))
      }
      return isValid
    }
    setStepHandler({ onClickNext })
  }, [getValues, trigger, setEnrollmentInfo, setStepHandler, setIsError])

  useEffect(() => {
    if (enrollmentInfo) {
      reset({
        ...enrollmentInfo.personalInfo,
        ...enrollmentInfo.mailingAddress,
        ...enrollmentInfo.contactInfo,
      })
    }
  }, [enrollmentInfo, reset])

  return (
    <>
      <FormProvider {...methods}>
        <h3>{t('PERSONAL_INFO')}</h3>
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <Name />
          <DateOfBirth />
          <Gender />
          <Ssn optionalText={t('SSN_HELP_TXT')} />
        </div>
        <h3>{t('MAILING_ADDRESS')}</h3>
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <Country />
          <Address />
          <City />
          <div className={styles.halfRow}>
            <State isCanada={isCanada} />
            <Zip isCanada={isCanada} />
          </div>
        </div>
        <h3>{t('CONTACT')}</h3>
        <p className={styles.contactText}>{t('CONTACT_TXT')}</p>
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <Email />
          <Phone />
        </div>
      </FormProvider>
    </>
  )
}
