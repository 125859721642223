import { useQuery } from '@tanstack/react-query'

import { FeatureToggleName } from '../../../types/enums'
import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

const request = endpoints.FeatureToggle_Get.getApiRequest()

type FeatureToggleMap = Record<FeatureToggleName, boolean>
const defaultToggles = Object.values(FeatureToggleName).reduce((all, item) => {
  all[item] = false
  return all
}, {} as FeatureToggleMap)

export const useGetFeatureToggles = () => {
  return useQuery({
    queryKey: queryKeys.getFeatureToggles(),
    queryFn: async () => {
      const data = await request({})
      return data.featureToggles.reduce(
        (all, item) => {
          all[item.name as FeatureToggleName] = item.enabled
          return all
        },
        { ...defaultToggles },
      )
    },
  })
}
