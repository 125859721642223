import { type FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { AddMemberContext } from '../../../context/AddMemberContext'
import { FormDropdownField } from '../../../uiComponents/Form/FormDropdownField'

import * as styles from './Employment.module.css'

export const EligibleEmpGrp: FC = () => {
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="eligibleEmpGrp">
        <FormDropdownField
          name="eligibleEmpGrp"
          label={t('ELIGIBLE_EMP_GRP')}
          options={
            enrollmentInfo.memberGroups?.map((memberGroup) => ({
              label: memberGroup.name,
              value: memberGroup.id,
            })) ?? []
          }
          required
        />
      </div>
    </>
  )
}
