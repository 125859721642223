import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export type UseGetEnrollmentInfoReturn = ReturnType<typeof useGetEnrollmentInfo>

const request = endpoints.Employer_EnrollmentInfo.getApiRequest()

export const useGetEnrollmentInfo = () => {
  return useQuery({
    queryKey: queryKeys.getEmployerEnrollmentInfo(),
    queryFn: () => request({}),
  })
}
