import { DateTime } from 'luxon'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DATE_OF_BIRTH } from '../../../../common/enums/regex'
import { FormDateField } from '../../../../uiComponents/Form/FormDateField'

import * as styles from './PersonalInfo.module.css'

interface DateOfBirthProps {
  minAge?: number
  maxAge?: number
  disabled?: boolean
  helperText?: string
}

export const DateOfBirth: FC<DateOfBirthProps> = ({
  minAge = 16,
  maxAge = 99,
  disabled = false,
  helperText,
}: DateOfBirthProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  const isDateValid = (dateString: { input: string }, minAge: number, maxAge: number): boolean => {
    const today = DateTime.now()
    const dob = DateTime.fromFormat(dateString.input, 'MM/dd/yyyy')

    if (dob.invalidReason) {
      return false
    }

    if (dob > today.minus({ years: minAge })) {
      return false
    }

    if (dob < today.minus({ years: maxAge })) {
      return false
    }

    return true
  }

  const validateDateOfBirth = (dateString: { input: string }): true | string => {
    if (dateString.input === '') {
      return t('PLEASE_MAKE_SELECTION')
    }
    if (isDateValid(dateString, minAge, maxAge)) {
      return true
    }
    return t('INVALID_DOB')
  }

  return (
    <div className={styles.textBoxMargin} data-testid="dob">
      <FormDateField
        name="dateOfBirth"
        label={t('DOB')}
        helperText={helperText}
        disabled={disabled}
        required
        rules={{
          pattern: {
            value: DATE_OF_BIRTH.MM_DD_YYYY.regex,
            message: DATE_OF_BIRTH.MM_DD_YYYY.message,
          },
          validate: validateDateOfBirth,
        }}
      />
    </div>
  )
}
